/** @see https://codesandbox.io/s/emoji-mart-test-unicode-test-xi64f */

import { fromUtf16Array, toUtf16Array } from "./utf16";
import { EmojiIndex } from "emoji-mart-vue-fast";
import data from 'emoji-mart-vue-fast/data/apple.json'

let tree = null;
let skin = ["1f3fb", "1f3fb", "1f3fc", "1f3fd", "1f3fe", "1f3ff"]; // 1&2 are the same, so we're adding 1 as 2
let emojiIndex = new EmojiIndex(data); /** @see https://github.com/serebrov/emoji-mart-vue/issues/44 */

function addToTree(seq, e) {
    function add(seq, e, skin) {
        let p = tree;
        seq.forEach(ch => {
            if (p[ch] == null) p[ch] = {};
            p = p[ch];
        });
        p["."] = { id: e.id, skin: skin };
    }

    if (e.skin) {
        skin.forEach((s, i) => {
            add([...seq, s], e, i + 1);
        });
    }
    add(seq, e);
}

function initTree() {
    if (tree == null) {
        tree = {};

        for (let k in emojiIndex._emojis) {
            let e = emojiIndex._emojis[k];
            let seq = e.unified.split("-");

            // graphics/text variant selectors?
            let last = seq[seq.length - 1];
            if (last === "fe0f" || last === "fe0e") {
                addToTree(seq, e);
                seq.pop();
                addToTree(seq, e);
            } else {
                addToTree(seq, e);
            }
        }
    }
}

function traverseFindSeq(seq) {
    //console.log("traverseFindSeq", seq);

    let p = tree;
    for (let i = 0; seq.length > i; i++) {
        let n = p[seq[i]];
        if (n == null) {
            return null;
        }
        p = n;
    }
    //console.log("traverseFindSeq result", p);
    return p;
}

export function getTextAndEmojiRuns(s) {
    initTree();

    let t0 = Date.now();

    let runs = [];
    let chars = toUtf16Array(s);
    let i = 0;
    let len = chars.length;
    let seq = [];
    let text = [];

    function flush() {
        //console.log("flush");
        if (seq.length > 0) {
            //console.log("flush seq");
            let e = traverseFindSeq(seq);
            runs.push({ type: "emoji", seq: seq, emoji: e["."] });
            seq = [];
        } else if (text.length > 0) {
            //console.log("flush text");
            runs.push({ type: "text", chars: text, text: fromUtf16Array(text) });
            text = [];
        }
    }

    while (len > i) {
        let c = chars[i++];
        let cc = c.toString(16).toLowerCase();

        // if we're in a sequence, check if there's more we can add to it
        if (seq.length > 0) {
            let m = traverseFindSeq([...seq, cc]);
            if (m == null) {
                m = traverseFindSeq(seq);
                if (m["."] != null) flush();
                else {
                    // abort, wasn't a complete, ending sequence we know..
                    text = [...text, ...seq];
                    seq = [];
                }
            } else {
                seq.push(cc);
                continue;
            }
        }

        let m = traverseFindSeq([cc]);

        if (m == null) {
            text.push(c);
        } else {
            flush();
            seq = [cc];
        }
    }
    flush();

    let t = Date.now() - t0;
    // console.log("time:", t);

    return runs;
}

export function countEmojis(text, zeroIfText = false)
{
    let runs = getTextAndEmojiRuns( text );

    let bHasText = runs.filter( ( v, i ) => v.type === 'text' ).map( ( v ) => v.text ).join( '' ).trim().length > 0;
    let iEmojis  = runs.filter( ( v, i ) => v.type === 'emoji' ).length;

    return zeroIfText ? (bHasText ? 0 : iEmojis) : (iEmojis);
}