import Video from './../Video';

/**
 * needs jquery!
 */
class CarouselVideo
{
    constructor({
        bMobile,
        $slick,
        $loading,
        slickBeforeChange = [],
        slickConfig = {},
        bMoreThanOneVideo = true,
        sPadding = 20,
        bUsePadding = true,
        videojs : { plugin : {playlist : { aPlayer = [] } = {}} = {}, ...videojsParams} = {},
    }) {

        this.bMobile     = bMobile;
        this.$slick      = $slick;
        this.$loading    = $loading;
        this.sPadding    = sPadding;
        this.bUsePadding = bUsePadding;

        // internals
        this.aVideoJsPlayers = [];
        this.aVideoIds       = [];
        this.initDone        = false;
        this.slickBeforeChange = slickBeforeChange;
        this.slickConfig     = Object.assign({
            centerMode   : bMoreThanOneVideo,
            centerPadding: `${this.sPadding}px`, // TODO use '20em' or something, instead raw number
            slidesToShow : 1,
            infinite     : false,
        }, slickConfig);
        this.videojs   = {
            fluid        : true,
            playbackRates: [0.25, 0.5, 0.75, 1, 1.5, 2],
            language     : "en",
            ...videojsParams, // overwrite
            plugin : {
                playlist : {
                    aPlayer
                }
            }
        };

        this.initCarousel();
    }

    initCarousel()
    {
        this.$slick
            .on('init', this._slickOnInit.bind(this))
            .on('beforeChange', this._slickOnBeforeChange.bind(this))
            .slick(this.slickConfig)
        ;
    }

    _slickOnInit(event, slick)
    {
        if(!this.initDone){ // videoJs fires multiple times, only handle once
            // hide loading
            this.$loading.removeClass('d-flex').addClass('d-none');
            // remove padding - first slide left padding counter, last slide right padding counter
            this.bUsePadding && (slick.$slider.find('.slick-track').css("margin", `0 -${this.sPadding}px`));
            // show slider
            slick.$slider.removeClass('d-none');
            // init video
            this.initVideo({slick});
            // remember init
            this.initDone = true;

        }
    }

    initVideo({slick})
    {
        // init videos
        this.aVideoIds       = this.generateVideoIds();

        // == new code (video visible including slick carousel)
        this.aVideoJsPlayers = Video.predefinedPlayLastVisible({
            videojs            : this.videojs,
            bMobile            : this.bMobile,
            aVideoIds          : this.aVideoIds,
            cbPlayerVisibleCustom: ({ player, video }) => {
                const playerId  = player.id();
                const index     = this.aVideoIds.indexOf(playerId); // a players in carousel
                const slideCurr = slick.currentSlide; // current active slide

                if(index >= 0){ // player (who should be played) is in carousel
                    return video.getPlayersNew()[slideCurr]; // play slide which is active
                }

                return player;
            }
        });

        // == old code (slick carousel self manages video-playback)
        // this.aVideoJsPlayers = this.initVideoJsPlayerAll();
        // // play first
        // !this.bMobile && (this.aVideoJsPlayers[0].play());
        // // handle play / pause @ visible
        // this.setAutoplayByVisibilityOnVideoJs({ slick, players : this.aVideoJsPlayers}); // $slick.get(0).slick
    }

    _slickOnBeforeChange(event, slick, currSlide, nextSlide)
    {
        const params = {event, slick, currSlide, nextSlide};
        // common
        this._slickVideoPlayback({
            iSlideVideoPause : currSlide,
            iSlideVideoPlay  : nextSlide
        });
        this._slickResizeSlides({slick, nextSlide});
        // custom
        this.slickBeforeChange.forEach(fn => fn(params));
    }

    _slickVideoPlayback({iSlideVideoPause = null, iSlideVideoPlay = null})
    {
        iSlideVideoPause !== null && (this.aVideoJsPlayers[iSlideVideoPause].pause());
        iSlideVideoPlay  !== null && (this.aVideoJsPlayers[iSlideVideoPlay].play());
    }

    _slickResizeSlides({ slick, nextSlide, bUsePadding = this.bUsePadding })
    {
        if(bUsePadding){ // mockup padding
            const $slickTrack = slick.$slider.find('.slick-track');
            switch(nextSlide){
                case 0:
                    $slickTrack.css("margin", `0 -${this.sPadding}px`);
                    break;
                case slick.slideCount - 1:
                    $slickTrack.css("margin", `0  ${this.sPadding}px`);
                    break;
                default:
                    $slickTrack.css("margin", "0 0");
                    break;
            }
        }
    }

    generateVideoIds($slick = this.$slick)
    {
        const aIds = [];
        const idPrepend = Math.random().toString(36).substr(2, 9); // https://gist.github.com/gordonbrander/2230317
        $slick.find('.slick-slide video').each((i,video) => {
            const id = ['carousel-video', idPrepend, i].join('-');
            $(video).attr('id', id);
            aIds.push(id);
        });

        return aIds;
    }

    initVideoJsPlayerAll(aIds = this.aVideoIds)
    {
        return aIds.map(id => this.initVideoJsPlayer(id));
    }

    /**
     *
     * @param id
     * @returns {*}
     */
    initVideoJsPlayer(id)
    {
        const {plugin = {}, ...config} = this.videojs; // plugin is no videojs-prop. could pass it, doesnt matter.
        const player = videojs(id, config);
        player.addChild('MuteToggleButton');

        return player;
    }

    setAutoplayByVisibilityOnVideoJs({slick, players})
    {
        this.$slick.on('inview', (event, isInView) => isInView // triggered, when gets in/out (true/false) of view
            ? !this.bMobile && (this.aVideoJsPlayers[slick.currentSlide].play())
            :  this.aVideoJsPlayers[slick.currentSlide].pause()
        );
    }
}

export default CarouselVideo;