var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"emoji-chrome",on:{"click":_vm.hide}}),_vm._v(" "),(!_vm.lazyInit)?_c('picker',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],ref:"picker",staticClass:"emoji",attrs:{"show-preview":false,"native":false,"color":"#2a91e1","i18n":{
              search: _vm.$t('emoji.search'),
              notfound: _vm.$t('emoji.notfound'),
              categories: {
                search: _vm.$t('emoji.categories_search'),
                recent: _vm.$t('emoji.categories_recent'),
                people: _vm.$t('emoji.categories_people'),
                nature: _vm.$t('emoji.categories_nature'),
                foods: _vm.$t('emoji.categories_foods'),
                activity: _vm.$t('emoji.categories_activity'),
                places: _vm.$t('emoji.categories_places'),
                objects: _vm.$t('emoji.categories_objects'),
                symbols: _vm.$t('emoji.categories_symbols'),
                flags: _vm.$t('emoji.categories_flags'),
                custom: _vm.$t('emoji.categories_custom'),
              }
            }},on:{"blur":_vm.focusout,"select":_vm.select}}):_vm._e(),_vm._v(" "),_c('button',{staticClass:"btn border-0 shadow-none d-flex align-items-center justify-content-center p-0",attrs:{"type":"button","disabled":!this.enabled},on:{"click":_vm.toggleVisibility}},[_c('i',{staticClass:"far fa-laugh fa-2x text-wd-gray-dark"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }