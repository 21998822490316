/**
 * needs jquery!
 */
class CarouselText
{
    constructor({
        isMobile,
        $slick, $loading,
        slickBeforeChange = [],
        slickInit = [],
        slickConfig = {},
        sPadding = 25, bUsePadding = true
    }) {
        this.isMobile    = isMobile;
        this.$slick      = $slick;
        this.$loading    = $loading;
        this.sPadding    = sPadding;
        this.bUsePadding = bUsePadding;

        // internals
        this.initDone          = false;
        this.slickInit         = slickInit;
        this.slickBeforeChange = slickBeforeChange;
        this.slickConfig       = Object.assign({
            centerMode   : true,
            centerPadding: `${this.sPadding}px`, // TODO use '20em' or something, instead raw number
            slidesToShow : 1,
            infinite     : false,
        }, slickConfig);

        this.initCarousel();
    }

    initCarousel()
    {
        // this._fixImageZero(); // fixed by using correct slick-version
        this.$slick
            .on('init', this._slickOnInit.bind(this))
            .on('beforeChange', this._slickOnBeforeChange.bind(this))
            .slick(this.slickConfig)
        ;
    }

    /**
     * caused by wrong slick-version
     *
     * @private
     */
    _fixImageZero()
    {
        const $dom      = this.$slick.find('img:not([data-src]):first'), // TODO url not broken ?
              dom       = $dom.length ? $dom[0] : null,
              iCheckMax = 5;
        let iCheckCurr = 1;

        var check = setInterval(function(){
            if($dom.length && dom.width === 0 && iCheckCurr < iCheckMax) { // slick, re-init by resize, if image is empty
                // console.log('slick, CarouselText -> img.width = 0 -> re-init slick by resize', $dom);
                $(window).trigger('resize'); // https://github.com/kenwheeler/slick/issues/235
            }else{
                // console.log('slick, CarouselText -> img.width > 0 -> clearing interval-check', $dom);
                clearInterval(check);
            }
            iCheckCurr++;
        }, 200);
    }

    _slickOnInit(event, slick)
    {
        if(!this.initDone){ // videoJs fires multiple times, only handle once
            // hide loading
            this.$loading.removeClass('d-flex').addClass('d-none');
            // remove padding - first slide left padding counter, last slide right padding counter
            this.bUsePadding && (slick.$slider.find('.slick-track').css("margin", `0 -${this.sPadding}px`));
            // show slider
            slick.$slider.removeClass('d-none');
            // remember init
            this.initDone = true;

            this.slickInit.forEach(fn => fn({event,slick}));
        }
    }

    _slickOnBeforeChange(event, slick, currSlide, nextSlide)
    {
        const params = {event, slick, currSlide, nextSlide};
        this._slickResizeSlides(params);
        // this._slickMogileLazyLoad(params);

        // custom
        this.slickBeforeChange.forEach(fn => fn(params));
    }

    _slickResizeSlides({ slick, nextSlide, bUsePadding = this.bUsePadding })
    {
        if(bUsePadding){ // mockup padding
            const $slickTrack = slick.$slider.find('.slick-track');
            switch(nextSlide){
                case 0: // first slide
                    $slickTrack.css("margin", `0 -${this.sPadding}px`);
                    break;
                case slick.slideCount - 1: // last slide
                    $slickTrack.css("margin", `0  ${this.sPadding}px`);
                    break;
                default: // in between
                    $slickTrack.css("margin", "0 0");
                    break;
            }
        }
    }

}

export default CarouselText;