import _throttle from "lodash/throttle";

/**
 * needs jquery!
 */
class Images {
    constructor({$images}) {
        this.$images = $images;
    }

    showOnVisible({ minVisibleMs = 500, removeClasses = [], cbDone = null } = {}) {
        /** @see https://github.com/protonet/jquery.inview */

        if(!this.$images) return this;

        const me = this;
        this.$images.on('inview', function (event, bInView) { // visible
            if (bInView) {
                const $this = $(this),
                      show  = () => { // visible for x milliseconds

                          const $elem = $this;
                          Images.setAttr({$elem : Images.getImage($elem)})

                          // const $elem = $this;
                          // if (me._isVisible({$elem})) { // still visible ? (if user is scrolling)
                          //     // console.log('visible', $this);
                          //     me._setAttr({$elem : Images.getImage($elem)})
                          // } else {
                          //     // console.log('not visible', $this);
                          // }
                      };
                if(minVisibleMs > 0){
                    setTimeout(show, minVisibleMs);
                }else{
                    show();
                }
            }
        });

        return this;
    }

    static setAttr({$elem = null, from = 'data-src', to = 'src'} = {})
    {
        if(!$elem) return null;

        $elem.attr(to, $elem.attr(from));
        $elem.removeAttr(from);

        return $elem;
    }

    _isVisible({$elem = null} = {}) {
        if(!$elem) return false;

        const min  = $(window).scrollTop(),           // window top not visible
              max  = min + $(window).innerHeight(),   // window height
              real = $elem.offset().top;              // element

        return min < real && real < max;
    }

    static getImage($elem)
    {
        return $elem.prop('tagName').toUpperCase() === 'IMG'
            ? $elem
            : $elem.find('img[data-src]');
    }

    static lazyLoad($elem)
    {
        $(Images.getImage($elem)).each((i,elem) => {
            Images.setAttr({$elem : $(elem)});
        })
    }

    showOnError({src = '/static/images/404_bg.jpg', bShowOnEmpty = true} = {}) {
        if (!this.$images) return this;

        if (src) {
            const me = this;
            this.$images.each(function(i, $dom){
                const $image = Images.getImage($(this));
                $image.on('error', function (e) {
                    const val = $(this).attr('src');
                    if(bShowOnEmpty || !bShowOnEmpty && val !== ''){
                        $(this).attr('src', src);
                    }
                });
            })
        }

        return this;
    }

    /**
     * // TODO any use ?
     */
    showOnLoadDone({ addClass = '', style = null} = {})
    {
        if (!this.$images) return this;

        if(addClass.length || addStyle ){
            this.$images.on('load', function(e){
                $(this).addClass(addClass)
                $(this).css(style)
            });
        }

        return this;
    }

    onLoad(cb)
    {
        const me = this;
        typeof cb === 'function' && this.$images.each(function(i,v){
          const $image = Images.getImage($(this)).on('load', cb);
        })

        return this;
    }

    static predefinedNewsOnStartPageByLazyLoadOnVisible(...args)
    {
        return new Images(...args)
            .onLoad(function(){ // add animation, move image to center again
                const $this = $(this);
                if($this.attr('data-src') === undefined){ // done by visibility -> which sets src, removes data-src
                    $this.parent().addClass('animated fadeIn faster');
                }
            })
            .showOnVisible({
                minVisibleMs : 0 // 0   -> trigger, if visible
                                 // 500 -> trigger, if visible for min of 500 ms (avoid loading all media on fast scrolling (and skipping media))
            }).showOnError({
                bShowOnEmpty : false
            });
    }

    static predefinedNewsOnStartPageShortBelowWindow({$images})
    {
        if($images.length)
        {
            Images.aImages.push($images);
        }else{
            console.log('images - lazy load : ', $images);
        }
    }

}

// =====================================================================================================================
//  predefinedNewsOnStartPageShortBelowWindow
// =====================================================================================================================

Images.aImages            = [];

if(window && $){ // play last fully visible
    $(function(){
        let fnHeightDesktop      = (multiply = 1) => $(window).innerHeight() * multiply,
            fnTopToDesktopBottom = () => $(window).scrollTop() + $(window).innerHeight(),
            iBottomLast          = fnTopToDesktopBottom(),
            iBottomDiff          = 100,
            iThrottle            = 200, // fire max 1 in x ms
            fnGetOffset          = ($elem) => ({
                min: $elem.offset().top,           // element
                max: $elem.offset().top + $elem.innerHeight(),          // element
            }),
            fnLazyLoad  = function(){
                if(Images.aImages.length){
                    const iBottomCurr = fnTopToDesktopBottom();
                    if(Math.abs(iBottomLast - iBottomCurr) > iBottomDiff)
                    {
                        // console.log('lazy-load-images', {itemsBefore : Images.aImages.length});
                        Images.aImages = Images.aImages.filter(($elem, i) =>{ // TODO if lazy loaded, remove from image-array
                            const iElemBottom = $elem.offset().top;
                            if(iBottomCurr + fnHeightDesktop(1.5) > iElemBottom ){
                                // console.log('lazy-load-images : loading', {
                                //     iBottomCurr,
                                //     offset : iElemBottom,
                                //     $elem,
                                //     id : $elem.parent().attr('id'),
                                // });
                                Images.lazyLoad($elem); // dev : deactivate, to see whats lazy-loaded
                                return false; // remove, is lazy loaded
                            }else{
                                // console.log('lazy-load-images : not loading', {
                                //     iBottomCurr,
                                //     offset : iElemBottom,
                                //     $elem,
                                //     id : $elem.parent().attr('id'),
                                // })
                            }
                            return true; // keep
                        });
                        iBottomLast = iBottomCurr;
                        // console.log('lazy-load-images', {itemsAfter : Images.aImages.length});
                    }
                }else{
                    // console.log('lazy-load-images : no items to lazy-load')
                }
            };

            fnLazyLoad = _throttle(fnLazyLoad, iThrottle);

        $(window).on('scroll resize', fnLazyLoad);
    })
}

export default Images;