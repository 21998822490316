<template>

    <div class="header">

        <!-- head -->
        <div class="card-body--no-padding">

            <!-- // image fills whole area, but is stretched + fully visible -->
            <img class="img-fluid w-100 header__img" :src="header.image" alt="404"/>

        </div>

        <!-- body -->
<!--        <vue-dog-profile-header-body></vue-dog-profile-header-body>-->

        <!-- footer -->
<!--        <vue-dog-profile-header-footer></vue-dog-profile-header-footer>-->

    </div>

</template>

<script>
// import _throttle      from 'lodash/throttle';
// import _get           from 'lodash/get';
import _isPlainObject from 'lodash/isPlainObject';

import {data} from './../../../js/layout/wd18774/page/dogProfile';
// import dogProfileHeaderHead from './header/head';
// import dogProfileHeaderBody from './header/body';
// import dogProfileHeaderFooter from './header/footer';

/** @file \application\modules\animal\views\scripts\viewv3\partial\header\body.phtml */

export default {
    components : { // use sub-components ?
        // 'vue-dog-profile-header-head'   : dogProfileHeaderHead,
        // 'vue-dog-profile-header-body'   : dogProfileHeaderBody,
        // 'vue-dog-profile-header-footer' : dogProfileHeaderFooter
    },
    props: {
        aHeader : {
          required : true,
        },
        aData : {
            type    : Object,
            required: true,
            default(){
                return [];
            },
            validator( value ) {
                return true;
            }
        },
    },
    data() { return {
        header : {
            image : 'https://cdn.working-dog.net/s03/bqChq2Q8asB6FFBaJ7_707VzFJUa7oNnRw81iWdnjrY,.jpg',
        }
    }; },
    computed: {},
    watch: {},
    methods: {},
    created(){},
    mounted(){
        // ...init javascript on vue-creation...
    }
}
</script>

<style lang="scss" scoped>
    .header__img {
        max-height: 260px;
        min-height: 250px;
    }
</style>