/**
 * == usage - only 1 promise - recommended using IFEE
 * var only1Promise = (function(){
 *   let ifReady = ready(...ready-check...,()=> undefined, ...resolve-para...);
 *   return function(cb){
 *       ifReady.then((...resolve-para...)=>cb(...resolve-para...));
 *   }
 * })()
 *
 * == usage - multiple promises - not recommended
 * var multiplePromise = : function(cb){
 *     return ready(...ready-check..., cb, ...resolve-para...);
 * }
 */

function wait(resolve, cbIsReady, cbOnNotUsingPromise, cbResolvePara) {
    window.requestAnimationFrame(()=>{
        if(cbIsReady()){
            resolve(cbResolvePara());                  // promise.then
            _.isFunction(cbOnNotUsingPromise) && cbOnNotUsingPromise(cbResolvePara());   // or callback
        }else{
            wait(resolve, cbIsReady, cbOnNotUsingPromise, cbResolvePara);
        }
    });
}

function ready(cbIsReady, cbOnNotUsingPromise = ()=> undefined, cbResolvePara = () => undefined){
    return new Promise((resolve, reject)=>{
        wait(resolve, cbIsReady, cbOnNotUsingPromise, cbResolvePara);
    });
}

function readyOnceIFEE(cbIsReady, cbResolvePara)
{
    let ifReady = ready(cbIsReady,() => undefined, cbResolvePara);
    return function(cbOnNotUsingPromise = () => undefined){
        ifReady.then((resolvePara)=>cbOnNotUsingPromise(resolvePara));
    }
}

export {
    ready,
    readyOnceIFEE
};
