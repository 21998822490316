<template>
    <span class="time-since" :title="getDay() + ' ' + $t('common.at_TIME_o_clock') | replace({'#1#':'', TIME : getTime()})">
        <template v-if="diffInSeconds < min">{{$t('common.right_now')}}</template><!-- e.g: gerade eben -->
        <template v-else-if="diffInSeconds < hour">{{$t('common.MIN_min') | replace({'#1#':'',MIN : getMin()}) }}</template><!-- e.g: seit X min -->
        <template v-else-if="diffInSeconds < day">{{$t('common.TIME_hour') | replace({'#1#':'',TIME : getHour()}) }} <!-- e.g: seit 13:45 Uhr --></template>
        <template v-else>{{getDay()}}
            <template v-if="!isTimeZero()">
                {{ $t('common.at_TIME_o_clock') | replace({'#1#':'',TIME : getTime()}) }}
            </template>
        </template>
    </span>
</template>

<script>
export default {
    props: {
        timestamp: {
            type    : Number,
            required: true,
            default() { // btw: php-timestamp is 1000 shorter, js-timestamp is 1000 longer
                return new Date().getTime() / 1000;
            },
        },
        lang: {
            /** @see Default_Helper_View_BnDate */ // for clock-translation appearance
            type    : String,
            required: false,
            default() {
                return _.get( window, 'APP.lang', 'de' );
            },
        },
    },
    data() {
        return {
            min   : 60,
            hour  : 60 * 60,
            day   : 60 * 60 * 24,
            diffInSeconds : 0,
        }
    },
    computed: {
        timestampNow(){
            // php                  -> timestamp 10 positions
            // moment().unix()      -> timestamp 10 positions
            // moment(13)           -> timestamp 13 positions
            // new Date().getTime() -> timestamp 13 positions

            return moment().unix() * 1000; // 13
        },

        timestampReadable : function(){ // just to see in vue-dev-tools the readable value
            return moment(this.getTimestampNormalized()).format('MMMM Do YYYY, h:mm:ss a')
        }
    },
    watch: {},
    methods: {
        getTimestampReadable(timestamp)
        {
            return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');
        },
        getMin(){
            return Math.floor(this.diffInSeconds / 60).toFixed();
        },
        getHour(){
            return Math.floor(this.diffInSeconds / (60 * 60)).toFixed(); // 3600s = 1 hour
        },
        getTime(){
            moment.locale(this.lang);
            return moment(this.getTimestampNormalized()).format('LT');
        },
        isTimeZero()
        {
            return moment(this.getTimestampNormalized()).format('LT') === '00:00';
        },
        getDay(){
            moment.locale(this.lang);
            return moment(this.getTimestampNormalized()).format('DD.MM.YYYY');
        },
        getDiffInSeconds(){
            return this.getTimestampNow() - this.getTimestampNormalized();
        },
        getTimestampNow()
        {
            // php                  -> timestamp 10 positions
            // moment().unix()      -> timestamp 10 positions
            // moment(13)           -> timestamp 13 positions
            // new Date().getTime() -> timestamp 13 positions

            return moment().unix() * 1000; // 13
        },
        getTimestampNormalized() {
            let jsTimestampLength = 13,
                timestampFiller   = jsTimestampLength - (this.timestamp).toString().length;
            timestampFiller       = timestampFiller < 0 ? 0 : timestampFiller; // negative ? then dont fill up

            if(timestampFiller){ // fix for timestamp lesser then 10 positions
                timestampFiller -= 10 - this.timestamp.toString().length;
            }

            return this.timestamp * (10 ** timestampFiller); // php * 1000 to get js timestamp-format
        },
    },
    created(){
        let me = this, repeat = setInterval(calculate, 60 * 1000);

        calculate(); // initial

        // TODO use timestamp 13 instead 10, cause chat-messages timestamp in milliseconds now (13 positions)

        function calculate() {
            me.diffInSeconds = (me.getTimestampNow() - me.getTimestampNormalized())/1000;

            let buffer = 60;
            if ( me.diffInSeconds + buffer > me.day ) { // if date + time shown -> not changeable, not need to re-check
                clearInterval( repeat );
            }
        }

        // TODO check for moment.js before using it ?
    }
}
</script>

<style lang="scss" scoped>
    /* custom css */
</style>