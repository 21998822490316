<template>
    <div class="flex justify-content-center mr-3">
        <div class="px-2 py-1 text-center text-white float-right rounded opacity-1 duration-box">
            {{ duration }}
        </div>
        <div
            class="image-text-container"
        >
            <!-- <img
              :src="cover"
              class="center-block d-block mx-auto rounded-lg"
            /> -->
            <video
                :id="videojsId"
                ref="videoPlayer"
                poster="https://cdn.working-dog.net/s02/gXsRN130AhBxpQDfL_WVhUN7wNwki55SYLIz20OgxX0,.jpg"
                @mouseover="showVideoControls"
                @mouseleave="hideVideoControls"
            >
            </video>

            <p class="mt-3 text-dark video-title">
              {{ title }}
            </p>
            <p class="mt-3"><span class="mdi mdi-eye text-wd-menu-shadow"></span> <span class="text-wd-menu-shadow">{{  views }}k</span></p>
        </div>
    </div>
</template>

<script>
// import _throttle      from 'lodash/throttle';
// import _get           from 'lodash/get';
import _isPlainObject   from 'lodash/isPlainObject';
import axios            from 'axios';


export default {
    components : { // use sub-components ?
        // 'vue-component-x' : 'importedComponent'
    },
     props: {
        title: {
            type    : String,
            required: true,
        },
        cover: {
            type    : String,
            required: true
        },
        url: {
            type    : String,
            required: true,
        },
        duration: {
            type    : String,
            required: true,
        },
        views: {
            type    : String,
            required: false,
        },
    },
    data() {
        return {
            options: {
                class   : "video-js vjs-default-skin",
                muted   : true, // otherwise dom-exception, cause autoplay with sound is not allowed
                autoplay: true,
                controls: false,
                sources : [
                    {
                        // src: "https://www.youtube.com/watch?v=1wkPMUZ9vX4",
                        // type: "video/youtube"
                        src: "https://storage02.working-dog.net/gXsRN130AhAy4DQ0DdyO_PX1kIEt87lmouKz9mmti10,.mp4",
                        type: "video/mp4"
                    }
                ]
            },
            videojsId : 'videojs-'+Math.random().toString(36).substr(2, 9)
        }
    },
    computed: {},
    watch: {},
    methods: {
        showVideoControls() {
            videojs(this.$refs.videoPlayer, this.options).play(); // works
        },
        hideVideoControls() {
            // this.$refs.videoPlayer.controls = false;
            videojs(this.$refs.videoPlayer, this.options).pause();
        },
    },
    created(){
    },
    mounted(){
        // setTimeout(()=> {
        //     videojs(this.$refs.videoPlayer, this.options).play();
        // }, 1000)
    }
}
</script>

<style lang="scss" scoped>
    .duration-box {
        margin-bottom: -70px;
        margin-right: 10px;
        margin-top: 10px;
        z-index: 2;
        position: relative;
        background-color: #000;
        opacity: 0.6;
    }

    .eye-gray-color {
        color: #bfbfbf;
    }

    .video-title {
        line-height: 1.6;
    }
</style>