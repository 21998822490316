import VueTranslation from './vue-translation';// vue-template, e.g: {{ $t('transFile.key') }}, btw: uses /public/static/vue-translation.generated.js
import VueComponents  from './vue-components'; // add new components here
import VueInit        from './vue-init';       // call window.vueInit() to re-initialize vue on ajax containing vue
import './vue-directives';                     // e.g: v-my-directive
import './vue-mixins';                         // e.g: global methods inside each vue-component
import './vue-filters';                        // filter functions, e.g: {{ value | filter1 | filterN(arg2, arg3) }}

VueInit('.vue-app, .vue-init', VueComponents, VueTranslation.i18n);

// =====================================================================================================================
//  dynamic import test
// =====================================================================================================================

// import VueTranslation from './vue-translation';// vue-template, e.g: {{ $t('transFile.key') }}, btw: uses /public/static/vue-translation.generated.js
// import VueComponents  from './vue-components'; // add new components here
// import VueInit        from './vue-init';       // call window.vueInit() to re-initialize vue on ajax containing vue
// import './vue-directives';                     // e.g: v-my-directive
// import './vue-mixins';                         // e.g: global methods inside each vue-component
// import './vue-filters';                        // filter functions, e.g: {{ value | filter1 | filterN(arg2, arg3) }}

// const test = () => import('./vue-components'); // add new components here
//
// test().then((...args) => {
//     console.log('lazy import', ...args);
// });

// setTimeout(() => {
//     Promise.all([
//         (() => import('./vue-components'))(), // add new components here
//     ]).then((...args) => {
//         console.log('lazy loading, finished', ...args);
//         // VueInit('.vue-app, .vue-init', VueComponents, VueTranslation.i18n);
//     })
// }, 2000);
