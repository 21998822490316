/**
 * @see https://www.npmjs.com/package/videojs-playlist
 * @see https://github.com/brightcove/videojs-playlist/blob/master/docs/api.md // docs
 *
 * // events - videojs
 *  @see https://docs.videojs.com/docs/api/player.html#Eventserror // doc, some
 *  @see https://github.com/videojs/video.js/blob/master/src/js/player.js#L1183 // all
 *
 * // events - plugin
 *  @see https://github.com/brightcove/videojs-playlist/blob/master/docs/api.md#events
 */
class Playlist {

    constructor({player, aPlaylist = [], bInstantInit = true}) {
        this.player    = player;
        this.aPlaylist = aPlaylist;

        bInstantInit && this.init();
    }

    init()
    {
        if(this.isPluginLoaded() && this.isPlaylist())
        {
            this
                .loadPlaylist()
                .setAutoplayNext()
                // .setThumbnails() // disabled for now (to much bandwidth)
            ;
        }else{
            // console.log('videojs - plugin - playlist - init - empty', {
            //     isPluginLoaded : this.isPluginLoaded(),
            //     isPlaylist     : this.isPlaylist(),
            //     aPlaylist      : this.aPlaylist,
            // })
        }
    }

    loadPlaylist()
    {
        this.player.playlist(this.aPlaylist);

        return this;
    }

    setAutoplayNext()
    {
        this.player.playlist.autoadvance(0);

        return this;
    }

    setThumbnails()
    {
        this.onEventPlaylistItem(({player}) => {
            /**
             * @see https://www.npmjs.com/package/videojs-thumbnails // api
             * @see \js\library\videojs\videojs.thumbnails.js // usage
             */
            player && player.thumbnails && player.thumbnails({});
        });

        return this;
    }

    isPluginLoaded()
    {
        return typeof this.player.playlist === 'function';
    }

    isPlaylist()
    {
        return Array.isArray(this.aPlaylist) && this.aPlaylist.length > 0;
    }

    onEventPlaylistItem(cb)
    {
        this.player.on('playlistitem', (...args) => {
            // console.log('playlist -> event : playlistitem', ...args);
            cb && cb({player : this.player, ...args});
        });

        return this;
    }
    onEventPlaylistEnded()
    {
        let player = this.player;
        this.player.on('ended', function(...args){
            // console.log('playlist -> event : ended', ...args);
            const
                iItemCurr = player.playlist.currentItem(),
                iItemLast = player.playlist.lastIndex()
            ;
            if(iItemCurr > 0 && iItemCurr === iItemLast ){
                // console.log('playlist -> event : finished last item', ...args);

                // == show-hide - new code
                // done via css-classes

                // == show-hide -> old code
                // let $player = $('#' + player.id_),
                //     $teaser = $player.next('a.teaser-show')
                // ;
                // if(   $player.length
                //    && $player.hasClass('teaser-hide')
                //    && $teaser.length
                //    && $teaser.hasClass('teaser-show')
                // ){
                //     $player.addClass('d-none');
                //     $teaser.removeClass('d-none');
                // }
            }
        });

        return this;
    }

}

export default Playlist;