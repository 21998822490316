import Vue                         from "vue";
import VueContentEditableDirective from 'vue-contenteditable-directive';
import VueContentEditable          from 'vue-contenteditable';
import VueLazyload                 from "vue-lazyload"; /** @see https://www.npmjs.com/package/vue-lazyload*/

// load plugin
Vue.use(VueContentEditableDirective);
Vue.use(VueContentEditable);
Vue.use(VueLazyload, {
    dispatchEvent: true, // js-event-trigger to e.g. modify css for custom animation (loading-animation)
                         // use @loading="fn", @error="fn", @loaded="fn" to fetch event & do something (e.g. css-animation)
    error        : '/static/images/404_bg.jpg',
 // loading      : '/static/images/animations/wait_287_246.gif', // dont use  (otherwise loading by gif + css-animation is both visible)
    attempt      : 1
});

// =====================================================================================================================
//  dynamic import test
// =====================================================================================================================

/** https://vueschool.io/articles/vuejs-tutorials/lazy-loading-and-code-splitting-in-vue-js/ */
// const VueLazyLoad = () => import('vue-lazyload');
// VueLazyLoad().then(plugin =>
//     Vue.use(plugin, {
//         error  : '/static/images/404_bg.jpg',
//         loading: '/static/images/animations/wait_287_246.gif',
//         attempt: 1
//     })
// );
