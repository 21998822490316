// TODO sources -> disable vtt-text

class VideoJsSetup {

    // public class fields / class properties (instance fields, static fields)
    // ...not working, cause webpack-config ?
    /** @see https://github.com/webpack/webpack/issues/9708#issuecomment-532781113 */

    constructor(mIdOrDomOfVideoTag, config = {}) {

        // console.log('VideoJsSetup');

        // defaults
        this.mPlayerAccess           = null;
        this.oPlayerInstance         = null;
        this.aPlayerPlaylist         = [];
        this.bPlayerQualitySelector  = true;
        this.bPlayerMuteToggleButton = true;

        this.config = {
            fluid        : true,
            playbackRates: [0.25, 0.5, 0.75, 1, 1.5, 2],
            preload      : "none", // default - preload : "auto"
            language     : window['APP'] && window['APP']['lang'] ? window['APP']['lang'] : "en",
            /** @see https://docs.videojs.com/docs/guides/languages.html#determining-player-language */

            // fetch-player
            autoplay     : true,
            controls     : true,
            muted        : false,  // TODO check audioOff & is mobile ?
            muteToggle   : true, // if true -> misplaced toggle button // misplaced anyway
        }

        // assigns
        this.playerAccess = typeof mIdOrDomOfVideoTag === 'string'
            ? window.document.querySelector('#'+mIdOrDomOfVideoTag) // TODO already contains # ?, dom-element null / not found
            : mIdOrDomOfVideoTag
            ;
        this.config       = Object.assign(this.config, config);
    }

    /**
     * to prevent video-download on rmb (right-mouse-button) context-menu
     *
     * @returns {VideoJsSetup}
     */
    setPreventRMBContextMenu()
    {
        this.playerAccess.addEventListener('contextmenu', function(e){
            e.preventDefault(); /** @see https://github.com/videojs/video.js/issues/2043 */
        });

        return this;
    }

    useMuteToggleButtonInsideVideo(bool)
    {
        this.bPlayerMuteToggleButton = !!bool;

        return this;
    }

    useQualitySelector(bool)
    {
        this.bPlayerQualitySelector = !!bool;

        return this;
    }

    usePlaylist(aPlaylist)
    {
        this.aPlayerPlaylist = Array.isArray(aPlaylist)
            ? aPlaylist
            : this.aPlayerPlaylist
        ;

        return this;
    }

    init()
    {
        this.oPlayerInstance = videojs(this.playerAccess, this.config, () =>
        {
            this.oPlayerInstance.on('playlistitem',  () => {
                this.oPlayerInstance.thumbnails && this.oPlayerInstance.thumbnails({});
            });

            if(this.bPlayerMuteToggleButton)
            {
                // bug audio on/off button on top-right-side in video
                this.oPlayerInstance.addChild('MuteToggleButton'); // not player.controlBar.addChild(), then its misplaced
            }
            if(this.bPlayerQualitySelector)
            {
                // video-quality-selector
                /** @see https://github.com/silvermine/videojs-quality-selector */
                this.oPlayerInstance.controlBar.addChild('QualitySelector', {}, 10); // dont add at ending, add "before"
                // video-quality-selector -> doesnt matter
                // player.on('qualitySelected', function (event, newSource) {
                //     // btw: video ended -> switching video -> playback at beginning without start -> not firing yet
                //
                //     // prevents firing multiple times
                //     /** @see https://docs.videojs.com/docs/api/player.html#Methodspaused */
                //     let bCanFire = !this.player().paused() || this.player().ended();
                //     if (bCanFire) {
                //         console.log('qualitySelected -> can fire');
                //     }
                // });
                // video-quality-selector -> doesnt matter
                // player.on('qualityRequested', function (event, newSource) {
                //     // console.log('qualitySelector -> qualityRequested', {event, newSource});
                //     console.log('qualityRequested');
                // });
            }
            if(this.aPlayerPlaylist)
            {
                // playlist
                /** @see https://github.com/brightcove/videojs-playlist/blob/master/docs/api.md */
                // let aSources        = [sources()[0][0]]; // array 1 source
                // let bSourcesSeveral = true;
                // if (bSourcesSeveral) {
                //     aSources = [ // array of several sources
                //         sources()[0][0],
                //         sources()[1][0],
                //     ];
                // }
                this.oPlayerInstance.playlist(this.aPlayerPlaylist);
                this.oPlayerInstance.playlist.autoadvance(0);
            }

            /** @see https://github.com/brightcove/videojs-thumbnails */
            if(this.oPlayerInstance.thumbnails)
            {
                this.oPlayerInstance.thumbnails({}); // init text-tracks (mouse-hover time -> show preview-image)
            }
        })

        return this;
    }

    getPlayer()
    {
        return this.oPlayerInstance;
    }
}

export default VideoJsSetup;