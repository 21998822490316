import Vue from 'vue';

// global
Vue.mixin(getMixins());

// vue-config
function getMixins()
{
    return {
        methods : {
            replace:replaceAll
        }
    }
}

// definitions
function replaceAll(s, replace = {}) {

    Object.keys(replace).forEach(key => {
        s = s.replace(new RegExp(key, 'g'), replace[key]);
    });

    return s;
}