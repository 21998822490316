import Vue from 'vue';

export default function(scope, VueComponents, i18n){
    window   && (window.Vue = window['Vue'] || Vue); // make vue available for custom-templates
    window   && (window.vueInit = vueInit); // make VueInit function available for re-initializing vue on new html by ajax (containing vue-components)
    document && vueInit(); // initializing vue, when page starts

    /**
     * call me if you want to initialize vue + re-initialize vue, when you have new html by ajax containing vue-components
     */
    function vueInit(scope = '.vue-app, .vue-init') {
        let vueScope = document.querySelectorAll(scope);

        vueScope.forEach((dom, k) => { // vue only on certain dom-areas not whole page (otherwise vue-errors cause of messy wd-code)
            let vueHtmlTags     = VueComponents.htmlTags.join(', '),
                scopeComponents = dom.querySelectorAll(vueHtmlTags);

            if (scopeComponents.length) { // only "new Vue", if components (by html-tags) inside
                new Vue({el: dom, i18n: i18n}); // initialize vue
            }
        });
    }
}