import Json   from './../helper/Json';
import Mogile from './../Mogile';

class SlickMogile {

    static getAndInjectIntoOnBeforeChange(addParams)
    {
        return async function getAndInjectIntoOnBeforeChange_inside(slickParams){
            slickParams = Object.assign(slickParams, addParams || {});
            await SlickMogile.onBeforeChange(slickParams);
        }
    }

    static async onBeforeChange({ slick, currSlide, nextSlide,
        lazyLoad : {
            iAmount   : iLazyLoadAmount= SlickMogile._iLazyLoadAmount,
            sSelector : sLazyLoadSelector = SlickMogile._sLazyLoadSelector
        } = {}
    })
    {
        const
            $slides          = SlickMogile._getSlidesToLazyLoad({slick, sLazyLoadSelector, iLazyLoadAmount, nextSlide}),
            aMogileParameters= SlickMogile._getDataToFetch({$slides, sLazyLoadSelector}).map(Json.decode);

        if(aMogileParameters.length){
            const aMogileUrls = await Mogile.fetchUrls({aMogileParameters});
            if(aMogileUrls.length){
                $slides.find(sLazyLoadSelector).each(function(i,v){
                    // TODO add image cache
                    const url = aMogileUrls[i];

                    if(!!url){
                        $(this).parent()
                            // TODO ja create image -> same url -> trigger loading ONLY once!
                            .after(`<img src="${url}" class="img-fluid">`)              // second
                            .after(`<img src="${url}" class="blur bg-wd-gray-light">`)  // first
                            .remove()
                        ;
                    }
                })
            }
        }
    }

    static _getSlidesToLazyLoad({slick, sLazyLoadSelector, iLazyLoadAmount, nextSlide})
    {
        // always 3 visible
        // - left   slide 10px
        // - center slide completely
        // - right  slide 10px

        // lazy load on "next" is 2 -> 4+5+6    // via 2 mod 3 => 2
        // lazy load on "next" is 5 -> 7+8+9    // via 5 mod 3 => 2
        // lazy load on "next" is 8 -> 10+11+12 // via 8 mod 3 => 2

        if((nextSlide+1) % 3 !== 2)
        {
            return $();
        }

        // debug
        // slick.$slider.find('.slick-slide').each(function(v,i){
        //    console.log({
        //        'slice'      : [nextSlide, nextSlide + 1 + iLazyLoadAmount],
        //        'sliceItems' : slick.$slider.find('.slick-slide').slice(nextSlide, nextSlide + 1 + iLazyLoadAmount + 1),
        //        'sliceIndex' : $(this).index(),
        //    });
        // });

        return slick.$slider
            .find('.slick-slide')
            .slice(
                nextSlide               // from
                , nextSlide             // 2 : current (image should already exist)
                + 1                   // 3 : image should already exist
                + iLazyLoadAmount     // 6 : images should be lazy loaded
                + 1                   // slice index + 1 to include next
            )
            .filter(function(v,i){
                return $(this).find(sLazyLoadSelector).length;
            });
    }

    static _getDataToFetch({sLazyLoadSelector, $slides})
    {
        return $slides
            .map(function(i,v){return $(this).find(sLazyLoadSelector).val();})
            .toArray()
            ;
    }

}

// static
SlickMogile._iLazyLoadAmount = 3;
SlickMogile._sLazyLoadSelector = 'input[type="hidden"][name="slick-mogile-lazy-load"]';

export default SlickMogile;