import VideoJsPluginQualitySelector from './VideoJsPlugins/QualitySelector.js';
import VideoJsPluginPlaylist from './VideoJsPlugins/Playlist.js';

/**
 * needs jquery!
 */
class Video {
    constructor({
        bMobile,
        aVideoIds,
        videojs : { plugin : {playlist : { aPlayer = [] } = {}} = {}, ...videojsParams} = {},
        bAutoplay = false,
        cbPlayerVisibleCustom = null,
    }) {
        this.bMobile  = bMobile;
        Video.bMobile = bMobile;

        // internals
        this.aPlayersNew   = [];
        this.aVideoIds     = aVideoIds;
        this.videojs       = {
            /** @see https://docs.videojs.com/docs/guides/options.html#individual-options */
            fluid        : true,
            muteToggle   : false, // if true -> misplaced toggle button
            playbackRates: [0.25, 0.5, 0.75, 1, 1.5, 2],
            preload      : "none", // default - preload : "auto"
            language     : "en",   /** @see https://docs.videojs.com/docs/guides/languages.html#determining-player-language */
            ...videojsParams,  // overwrite
            plugin : {
                playlist : {
                    aPlayer
                }
            }
        };
        this.videoJsAddCustom      = ({player, iPlayerIndex = -1}) => {
            player.addChild('MuteToggleButton');
            new VideoJsPluginQualitySelector({player});
            new VideoJsPluginPlaylist({
                player,
                aPlaylist : this.getPlaylist({iPlayerIndex, player})
            });
        }
        this.bAutoplay             = bAutoplay;
        this.cbPlayerVisibleCustom = typeof cbPlayerVisibleCustom === 'function'
            ? cbPlayerVisibleCustom
            : null;
        this.init();
    }

    init()
    {
        this.initVideos({aVideoIds: this.aVideoIds});
    }

    initVideos({aVideoIds = []})
    {
        for (let i = 0; i < aVideoIds.length; i++) {
            const bAutoPlay = [true, false].includes(this.bAutoplay)
                ? this.bAutoplay
                : i === 0;      // first -> autoplay
            this.initVideo({
                sVideoId : aVideoIds[i],
                bAutoPlay
            } );
        }
    }

    initVideo({sVideoId, bEnableAutoplay}) {
        var player = this.initVideoJs({sVideoId});
        bEnableAutoplay && this.setAutoplay({
            player,
            bMobile : this.isMobile()
        });

        this.addPlayer(player);

        return player;
    }

    initVideoJs({sVideoId}) {
        const { plugin = {}, ...config } = this.videojs; // plugin is no videojs-prop. could pass it, doesnt matter.
        const player = videojs(sVideoId, config);
        this.videoJsAddCustom({
            player,
            iPlayerIndex : this.aVideoIds.indexOf(sVideoId)
        });

        return player;
    }

    isMobile()
    {
        return this.bMobile;
    }

    addPlayer(player)
    {
        Video.aPlayers.push(player);
        this.aPlayersNew.push(player);

        const playerId = player.id();

        // if(Video.videoByPlayer[playerId]){ // debug
        //     console.error('player already set !', playerId);
        // }

        Video.videoByPlayer[playerId] = this;
    }

    getPlayers()
    {
        return Video.aPlayers;
    }

    getPlayersNew()
    {
        return this.aPlayersNew;
    }

    setAutoplay({player, bMobile = false}) {
        const cbYes = bMobile
            ? () => null
            : () => player.play();

        Video.onView({
            $elem   : $(player.el_),
            visible : {
                cbNo  : () => player.pause(),
                cbYes
            }
        });
    }

    /**
     * player in selected players
     *
     * @param player
     * @returns {boolean}
     */
    isPlayer(player)
    {
        return this.aPlayersNew.reduce((bool, playerCurr) => bool || playerCurr.el_ === player.el_, false);
    }

    /**
     * use custom playback filter
     * @param player
     */
    getPlayerToPlay(player)
    {
        return this.isPlayer(player) && this.cbPlayerVisibleCustom
            ? this.cbPlayerVisibleCustom({player, video : this})
            : player;
    }

    static onView({$elem, visible : { cbYes, cbNo }})
    {
        $elem.on('inview', (event, isInView) => isInView ? cbYes() : cbNo());
    }

    static playLast()
    {
        if(Video.aPlayersVisible.length){
            const [lastPlayer = null, ...playersStop] = Video.aPlayersVisible.reverse();

            Video.aPlayersVisible = []; // clear
            playersStop.forEach(player => player.pause()); // pause
            Video.aPlayersPlaying.forEach(player => player.pause()); // just pause all, to get it working

            if(lastPlayer){ // player
                Video.aPlayersPlaying.push(lastPlayer);
                lastPlayer.play();
            }
        }else{
            console.log({ aPlayersVisible : Video.aPlayersVisible});
        }
    }

    static filterVisible(aFullyVisible = Video.aPlayersVisible)
    {
        const min           = $(window).scrollTop(),           // window top not visible
              max           = min + $(window).innerHeight(),   // window height
              getOffset     = ($elem) => ({
                  min: $elem.offset().top,           // element
                  max: $elem.offset().top + $elem.innerHeight(),          // element
              }),
              getPlayer$ = (player) => $(player.el_)
        ;

        return aFullyVisible.filter( player => {
            const $player = getPlayer$(player),
                  offset  = getOffset($player);

            return min < offset.min && offset.max < max;
        });
    }

    static predefinedPlayVisible({...props})
    {
        new Video({...props});
    }

    static predefinedPlayLastVisible({bMobile, ...params})
    {
        const aPlayersNew = new Video({...params, bMobile, bAutoplay : false}).getPlayersNew();

        aPlayersNew.forEach(player => {
            Video.onView({
                $elem  : $(player.el_),
                visible: {
                    cbNo : () => {
                        Video.aPlayersInView = Video.aPlayersInView.filter(playerItem => playerItem.el_ !== player.el_ )
                        player.pause();
                        // console.log('inView.removed', Video.aPlayersInView);
                    },
                    cbYes: bMobile
                        ? () => null // dont play
                        : () => { // play, if...
                            Video.aPlayersInView.push(player);
                            Video.playFirstVideoInstantlyOnInit(player);
                            // console.log('inView.added', Video.aPlayersInView);
                        }
                }
            });
        })

        return aPlayersNew;
    }

    static playFirstVideoInstantlyOnInit(player)
    {
        if(!Video.bPlayFirstVideoDone){
            Video.bPlayFirstVideoDone = true;
            player.play();
        }
    }

    getPlaylist({iPlayerIndex = -1, player = null})
    {
        return iPlayerIndex >= 0
            ? this.videojs.plugin.playlist.aPlayer[iPlayerIndex] || []
            : []
            ;
    }

}

Video.bMobile               = false; // overwritten by last video-init
Video.bPlayFirstVideoDone   = false;
Video.aPlayers              = [];
Video.aPlayersInView        = [];
Video.aPlayersInViewVisible = [];
Video.aPlayersPlaying       = [];
Video.videoByPlayer         = {};

// TODO add manually stopped by user, so they are not played

if(window && $){ // play last fully visible
    $(function(){
        let fnScroll    = () => $(window).scrollTop() + $(window).innerHeight(),
            iScroll     = fnScroll(),
            iScrollDiff = 100,
            iDebounce   = 500, // if idle for x ms, then fire
            stopAndStartPlay = _.debounce(function(){
                const iScrollCurr = fnScroll();
                if(Video.aPlayersInView.length && (Math.abs(iScroll - iScrollCurr) > iScrollDiff) )
                {
                    Video.aPlayersInViewVisible = Video.filterVisible(Video.aPlayersInView); // fully visible of all in-view-elements
                    iScroll                     = iScrollCurr;

                    if(Video.aPlayersInViewVisible.length){ // any full visible ?
                        // last player fully visible
                        const [playerLast] = Video.aPlayersInViewVisible.reverse();
                        // stop playing all inView players (except player who starts to play)
                        Video.aPlayersInView.forEach(playerStop => {
                            if(playerStop.el_ !== playerLast.el_){
                                if(!playerStop.isFullscreen()) // dont pause, if user clicks fullscreen
                                {
                                    playerStop.pause();
                                }
                            }
                        });
                        // start playing
                        if(!Video.bMobile){
                            const oVideo = Video.videoByPlayer[playerLast.id()];
                            oVideo.getPlayerToPlay(playerLast).play();
                        }
                    }
                }
            }, iDebounce);

        $(window).on('scroll resize', stopAndStartPlay);

        stopAndStartPlay(); // first time, no scroll, start video
    })
}

export default Video;