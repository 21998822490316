<template>
    <div>
        <div class="ce-test"
             @keydown.enter.exact.prevent="handleEnter"
             v-contenteditable:message.dangerousHTML="enabled"
             @keydown.ctrl.66.exact.prevent="handleCtrlB"
        ></div>
        <div class="ce-test" @keydown.enter.exact.prevent="handleEnter" v-contenteditable="message" ></div>
        <button @click="() => enabled = !enabled">contenteditable: {{enabled}}</button>
        <button @click="() => message = 'test'">set message to 'test'</button>
        <div class="data">
            {
            <br />&nbsp;&nbsp;message: "{{message}}" <br/>
            }
        </div>
    </div>
</template>

<script>

    /** @see https://codesandbox.io/s/n5zzm2qykj */ // source

    export default {
        props : {
          message : {
              // type     : String,
              required : false,
              validator: function( value ) {
                  return value;
              },
          }
        },
        data() {
            return {
                enabled: false,
            };
        },
        methods: {
            testBlur() {
                console.log("hi");
            },
            handleEnter(e) {
                // document.execCommand("InsertParagraph", false, null);
                // document.execCommand("FontSize", false, 7)
                // document.execCommand("FormatBlock", false, "<h1>");
                // document.execCommand("Print", false)
            },
            handleCtrlB() {
                // document.execCommand("Bold", false, null);
            }
        },
        watch: {
            message(newVal, old){
                this.$emit('change', newVal);
            },
        },
        mounted() {
        }
    };
</script>

<style lang="scss" scoped>
    .ce-test {
        min-width: 100px;
        min-height: 30px;
        margin: 15px 0;
        border: solid grey 2px;
        padding: 5px;
    }
    .ce-test[contenteditable="true"] {
        font-style: italic;
        background: #f4f4f4;
    }
    .data {
        font-family: monospace;
        text-align: left;
    }
</style>
