<template>

    <div class="video-js-wrapper container-fluid px-0">

        <div class="row">
            <!-- video title playing -->
        </div>
        <div class="row">

            <!-- video -->
            <div class="col-12">
                <video
                    ref="video"
                    :class="[
                        'video-js vjs-big-play-centered vjs-fluid w-100 bg-white '+
                        'rounded-8 overflow-hidden '+
                        'video-play-paused-styling '
                    ]"
                    :poster="null"
                    controls
                    preload="none"
                >
                    <!-- TODO add sources without playlist as no-playlist-fallback -->
                </video>
            </div>

            <!-- below video : parts of video (each playlist-item) -->
            <div class="col-12" v-if="playlistShow && playlist.length > 1">
                <div class="row flex-nowrap overflow-auto mx-n1 mt-2">
                    <div v-for="({poster = null, title = null}, i) in playlist"
                         :key="i"
                         @click.prevent.stop="playItem(i)"
                         @mouseover="showTitle(i)"
                         @mouseout="showTitle(-1)"
                         :class="[ 'col-3 col-lg-2 position-relative my-auto px-1']"
                    >
                        <!-- shadow -->
                        <div v-if="playlistItemCurr === i" class="playlist-item__shadow-container px-1">
                            <div class="playlist-item__shadow-fit">
                                <div class="playlist-item__shadow-show rounded"></div>
                            </div>
                        </div>
                        <!-- image -->
                        <img class="img-fluid w-100 rounded" :alt="title" :src="poster || defaults.poster">
                        <!-- icon + text -->
                        <span class="position-absolute absolute-centered font-size-13px line-height-20px text-white text-truncate mw-100">
                            <span v-if="playlistItemCurr === i"
                                  :class="['mdi mdi-middle font-size-20px'
                                  +(playlistItemCurrState === playlistItemStates.PAUSE ? ' mdi-pause' : '')
                                  +(playlistItemCurrState === playlistItemStates.PLAY  ? ' mdi-play-circle' : '')
                                  ]"></span>
                            <span class="line-height-20px">{{$t('movieplayer.part')}} {{i+1}}</span>
                        </span>
                    </div>
                </div>
            </div>

            <!-- below video : title of current video-->
            <div class="col-12 text-white position-relative">
                <div class="position-absolute" style="z-index: 1;">
                    {{ title }}
                </div>
            </div>

        </div>

    </div>

</template>

<script>
// import playlist         from './../../js/videojs/playlist';
import VideoJsSetup from './../../../js/videojs/setup';
import Styles from './VideoJsWrapper.scss';

const playListItemStates = {
    PLAY  : 'PLAY',
    PAUSE : 'PAUSE',
};

export default {
    // components : {
    //     'vue-chat' : Chat
    // },
    props   : {
        playlist: {
            type    : Array,
            required: true,
            default : [], // { href, title, uri }
            validator(value) {
                return true; // TODO
            }
        },
        playlistShow:{
            type    : Boolean,
            required: false,
            default : true,
            validator(value){
                return true;
            }
        }
    },
    data() {
        return {
            defaults           : {
                poster: '/static/images/placeholder/no_movie_160_90.png'
            },
            videojs              : null,
            title                : null,
            playlistItemCurr     : -1,
            playlistItemCurrState: playListItemStates.PLAY,
            playlistItemStates   : playListItemStates,
        }
    },
    computed: {},
    watch   : {},
    methods : {
        initVideo() {
            // console.log('VideoJsWrapper -> initVideo');
            this.videojs = new VideoJsSetup(this.$refs.video)
                .setPreventRMBContextMenu()
                .useMuteToggleButtonInsideVideo(true) // online appears, if muted
                .useQualitySelector(true)
                .usePlaylist(this.playlist)
                .init()
                .getPlayer()
            ;
            this.initVideoEventsPlayer();
            this.initVideoEventsPluginPlaylist();
        },
        initVideoEventsPlayer()
        {

            this.videojs.on('error', (...args)=> {
                this.$emit('error', {
                    videojs: this.videojs,
                    error  : this.videojs.error()
                });
            });
            this.videojs.on('play', (...args)=>{
                // console.log('videojs -> play', ...args, this.videojs.playlist.currentItem());
                this.$emit('play', { videojs : this.videojs, ...args});
                this.playlistItemCurrState = playListItemStates.PLAY;
            });
            this.videojs.on('pause', (...args)=>{
                // console.log('videojs -> pause', ...args,this.videojs.playlist.currentItem());
                this.$emit('pause', { videojs : this.videojs, ...args});
                this.playlistItemCurrState = playListItemStates.PAUSE;
            });
        },
        initVideoEventsPluginPlaylist()
        {
            // playlist-plugin-events
            /** @see https://github.com/brightcove/videojs-playlist/blob/master/docs/api.md */
            this.videojs.on('playlistitem', (...args)=>{
                // console.log('playlistitem', ...args);
                this.$emit('playlistitem', {
                    videojs  : this.videojs,
                    itemCurr : this.videojs.playlist.currentItem(),
                    itemLast : this.videojs.playlist.lastIndex(),
                });
                this.playlistItemCurr = this.videojs.playlist.currentItem();
            })
            this.videojs.on('ended', (...args)=>{
                // console.log('ended', ...args);
                this.$emit('ended', { videojs : this.videojs, ...args});

                if(this.videojs.playlist.currentIndex() === this.videojs.playlist.lastIndex())
                {
                    this.$emit('endedLast', { videojs : this.videojs, ...args});
                }

                this.playlistItemCurr = -1;
            })
        },
        playItem(i)
        {
            this.playlistItemCurr = i;
            this.videojs
                ? this.videojs.playlist.currentItem(i)
                : console.log('error (VideoJsWrapper) - videojs not initialized ?')
        },
        showTitle(i)
        {
            this.title = i >= 0
                ? this.playlist[i]['title'] || null
                : null
            ;
        }
    },
    created() {},
    mounted() {
        !videojs
            ? console.log('warning (VideoJsWrapper) - videojs not available')
            : this.initVideo()
        ;
    },
    beforeDestroy() {
        if(this.videojs)
        {
            this.videojs.dispose();
        }
    }
}
</script>

<style lang="scss" scoped>

.rounded-8 {
    border-radius: 8px !important;
}

.mw-100 {
    max-width: 100%;
}
.absolute-centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// position-absolute doesnt contain padding -> this is a workaround
.playlist-item__shadow-container { // this contains the padding
    position : absolute;
    height   : 100%;
    right    : 0;
    left     : 0;
}

.playlist-item__shadow-fit { // set the new size by relative
    position : relative;
    height   : 100%;
    width    : 100%;
}

.playlist-item__shadow-show { // show shadow
    width      : 100%;
    height     : 100%;
    position   : absolute;
    background : black;
    opacity    : 0.5;
}
</style>