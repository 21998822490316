<template>

    <div class="w-100 px-0 d-flex flex-column justify-content-between">
        <div :class="{
                'chat-messages position-relative d-flex flex-grow-1 flex-column' : true,
                'min-height-messages-window-for-mobile' : overlay
            }" ref="elChatMessages">

            <div :class="{'remaining-height' : overlay, 'bg-wd-white-smoke' : true}">

                <div class="shadow-top"></div>

                <!-- pagination, load older comments -->
                <template v-if="pagination.use">
                    <div class="px-2">
                        <div class="d-flex justify-content-center">
                            <div class="btn btn-outline-primary btn-sm waves-effect waves-light"
                                 @click="getMessagesByPaginationNext"
                            >
                                {{ $t('chat.show_previous_COUNT_replies') | replace({'#1#':'', COUNT : ''}) }}
                            </div>
                        </div>
                    </div>
                </template>

                <!-- comments -->
                <template v-if="messageAndUserNormalized.length" ref="messageAndUser">

                    <div class="px-2" v-for="(comment, i) in messageAndUserNormalized">
                        <div class="d-flex align-items-center border-gray-light">

                            <!-- user left -->
                            <a class="user-image shape-circle border-white-smoke"
                               :href="comment.user.profile.length ? getWindow().location.origin + comment.user.profile : ''"
                               :title="comment.user.name"
                            >
                                <div class="img normalsize"
                                     :title="comment.user.name"
                                     :style="{'background-image' : `url('${comment.user.image.length ? comment.user.image : fallback.user.image}')` }"></div>
                                <span :class="['user-type', comment.user.premium ? 'bg-premium' : '']"></span>
                                <span v-if="comment.saving" class="mdi mdi-content-save mdi-1x icon-message-save" style="position: absolute; bottom: 0;"></span>
                            </a>
                            <!-- message right -->
                            <div class="ml-2 position-relative">

                                <!-- message text -->
                                <div :class="['chat-message-text-parent', 'mt-3', 'd-inline-block','position-relative', ]"
                                     :set="(iEmojiCount = countEmojis(comment.message.text, true))">
                                    <div :class="[ 'chat-message-text', 'p-1', 'rounded',
                                            iEmojiCount
                                                ? 'z-depth-0'
                                                : (comment.user.id === user.id ? 'bg-whats-app-green' : 'bg-white'),
                                            ]"
                                    >
                                        <!-- shorten text like so "words ..." -->
                                        <vue-text-ellipsis v-if="visible"
                                               :data="comment.message.text"
                                               :line-clamp="2"
                                               :line-height="'13px'"
                                               :end-char="`... ${$t('chat.read_more')}`"
                                               :end-html='`<span class="position-relative"><a class="position-absolute" style="white-space: nowrap; right: 0;">${$t("chat.read_more")}</a></span>`'
                                        ><vue-emoji-text :text="comment.message.text"
                                                         :size="iEmojiCount === 0 ? 16 : (iEmojiCount === 1 ? 24 : 20)"
                                                         :set="'apple'" /></vue-text-ellipsis><!-- show text complete if plugin to slow -->
                                        <template v-else><vue-emoji-text
                                            :text="comment.message.text"
                                            :size="iEmojiCount === 0 ? 16 : (iEmojiCount === 1 ? 24 : 20)" :set="'apple'" /></template>
                                    </div>

                                    <div v-if="user.canDelete" :class="[
                                                'chat-message-text-dropdown', 'position-absolute', 'h-100', 'rounded-right', 'border-left',
                                                iEmojiCount ? '' : 'bg-whats-app-green'
                                            ]">
                                        <div class="d-flex h-100 justify-content-center align-items-center text-white font-weight-bold px-1 dropdown-toggle"
                                             data-toggle="dropdown">
                                            <i class="fa fa-chevron-down text-wd-gray-dark"></i>
                                        </div>
                                        <div class="dropdown-menu">
                                            <a v-if="user.canDelete"
                                               class="dropdown-item"
                                               @click="deleteMessage($event, i)"
                                               href="#">
                                                {{ $t('chat.delete_message') }}
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <!-- message time -->
                                <div class="bg-white-smoke text-truncate text-wd-gray-dark">
                                    <vue-time-since :timestamp="comment.message.timestamp"></vue-time-since>
                                </div>

                            </div>
                        </div>
                    </div>

                </template>

                <!-- no comments -->
                <template v-else>
                    <template v-if="false"><!-- dont show -->
                        <div class="col-12">

                            <div class="text-center p-2">
                                {{ $t('community.no_comments') }}
                            </div>

                        </div>
                    </template>
                </template>

                <div class="shadow-bottom"></div>

            </div>
        </div>

        <!-- chat -->
        <div class="chat">
            <div class="col-12 bg-white d-flex align-items-center mt-1">

                <!-- user left -->
                <a class="user-image shape-circle mx-2 my-1 pr-1"
                   :href="user.profile.length ? getWindow().location.origin + user.profile : ''"
                   :title="user.name"
                   style="width: 35px; height: 35px;"
                >
                    <div class="img normalsize"
                         :title="user.name"
                         :style="{'background-image' : `url('${user.image.length ? user.image : fallback.user.image}')`, 'width' : '35px', 'height' : '35px' }"></div>
                    <span :class="['user-type', user.premium ? 'bg-premium' : '']" style="width: 10.5px; height: 10.5px;"></span>
                </a>

                <!-- chat online : disabled set if online/offline -->
                <div class="md-form flex-grow-1 my-0 d-flex py-1 pl-1">
                    <!-- focus, remove bottom line by removing box-shadow via z-depth-0 -->
                    <textarea
                        :disabled="!socket.connection"
                        ref="elMessage"
                        class="md-textarea w-100 border-0 py-0 z-depth-0"
                        :placeholder="$t('community.add_comment')"
                        spellcheck="false"
                        v-model="input.text"
                        :rows="input.rows"
                        @keyup.enter="sendMessage"
                        @focusin="$refs.emoji.hide()"
                    ></textarea>
<!--                    <vue-content-editable ref="elMessage" @change="(val) => input.text = val" :message="input.text"></vue-content-editable>-->
<!--                    <contenteditable class="w-100" style="border: 1px solid red;" tag="div" :contenteditable="true" v-model="input.text" :no-h-t-m-l="false" :no-n-l="false" ></contenteditable>-->
                </div>

                <vue-emoji-picker :enabled="socket.connection" ref="emoji" @emojiSelected="addEmoji"></vue-emoji-picker>

                <!-- chat offline feedback -->
                <div v-if="!socket.connection" class="position-absolute h-100 w-100">
                    <div class="d-flex align-items-center justify-content-center h-100 mr-3 bg-white">
                        <!-- message not animated -->
                        <div v-if="socket.error.authorization" class="d-flex align-items-center ">
                        <span class="mdi mdi-chat-alert mdi-24px text-wd-gray-dark d-flex align-items-center">
                            <span class="pl-1">{{ $t('chat.socket_error_not_authorized') }}</span>
                        </span>
                        </div>
                        <!-- message not animated -->
                        <div v-else-if="socket.error.unknown" class="d-flex align-items-center ">
                        <span class="mdi mdi-chat-alert mdi-24px text-wd-gray-dark d-flex align-items-center">
                            <span class="pl-1">{{ $t('chat.socket_error_unknown') }}</span>
                        </span>
                        </div>
                        <!-- message animated -->
                        <div v-else class="animated fadeOut slower infinite d-flex align-items-center ">
                        <span class="mdi mdi-chat-alert mdi-24px text-wd-gray-dark d-flex align-items-center">
                            <span class="pl-1">{{ $t('chat.socket_connecting') }}...</span>
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import inViewPort      from 'in-viewport';
    import Socialize       from '../../../js/layout/wd18774/socialize/Socialize'; // socket

    import EmojiText       from "./../emoji/Text.vue"; // TODO adds all.json
    import EmojiPicker     from './../emoji/Picker.vue';
    import {countEmojis}   from "./../../lib/emojiText/emoji-parse";

    import ContentEditable from "./input/ContentEditable.vue"; // input, show emojis
    import TextEllipsis    from "./message/TextEllipsis.vue"; // shorten text, show "show more"

    export default {
        components: {
            'vue-emoji-picker'    : EmojiPicker,
            'vue-emoji-text'      : EmojiText,
            'vue-content-editable': ContentEditable,
            'vue-text-ellipsis'   : TextEllipsis,
        },
        props: {
            user: {
                type     : Object,
                required : true,
                validator: function( value ) {
                    return _.isPlainObject( value ) && [
                        'id',       // number, 0
                        'name',     // string, 'Max Mustermann', // 'http://dev.working-dog.eu/user/Steffen-Grabaum-21918'
                        'profile',  // string, 'http://dev.working-dog.eu/user/Max-Mustermann-0'
                        'image',    // string, '/static/images/placeholder/v3/no_user_v3_100_100.png'
                        'premium',  // boolean, false
                        'canDelete',// boolean, true, false
                    ].every(v => _.has(value, v));
                },
            },
            entity: {
                type     : Object,
                required : true,
                validator: function( value ) {
                    return _.isPlainObject( value ) && [
                        'id',       // number, 0
                    ].every(v => _.has(value, v));
                },
            },
            fallback: {
                type    : Object,
                required: false,
                validator: function( value ) {
                    return _.isPlainObject( value ) && [
                        'user.image' // '/static/images/placeholder/v3/no_user_v3_100_100.png'
                    ].every(v => _.has(value, v));
                },
                default() {
                    return {
                        user: {
                            image  : '/static/images/placeholder/v3/no_user_v3_100_100.png',
                            name   : '',
                            premium: false,
                            profile: '',
                        }
                    }
                },
            },
            overlay : false
        },
        data() {
            return {
                input              : {
                    text: '',
                    rows: 1,
                },
                messageAndUser     : [],
                socket             : {
                    connection: false,
                    error     : {
                        authorization: false,
                        unknown      : false,
                    },
                },
                socialize          : null,
                dataComputedTrigger: false,
                visible            : false,
                emojiLazyInit      : true,
                pagination         : {
                    use    : false,
                    server : { // server, known pagination values
                        page  : 0, // not -1 (which is default), so pagination is active (-1 will return all results, no pagination)
                        range : {
                            timestamp : {
                                lt : new Date().getTime()
                            }
                        }
                    }
                }
            }
        },
        computed: {
            messageAndUserNormalized() {
                this.dataComputedTrigger = !this.dataComputedTrigger;
                return this.messageAndUser.map( v => ({
                    saving    : _.get( v, 'saving', false ),
                    user      : _.merge( { error: false, }, _.get( v, 'user', {
                        error  : true,
                        image  : this.fallback.user.image,
                        name   : this.fallback.user.name,
                        premium: this.fallback.user.premium,
                        profile: location.host + this.fallback.user.profile,
                    } ) ),
                    message   : _.merge( { error: false }, _.get( v, 'message', {
                        error    : true,
                        newsId   : this.entity.id,
                        userId   : this.user.id,
                        timestamp: 0,
                        text     : '',
                        textToLong: _.get( v, 'message.textToLong', false ),
                        textShowFull: _.get( v, 'message.textShowFull', false ),
                    } ) ),
                }) );
            },
        },
        watch: {
            'input.text'(newVal, old){
                if(typeof newVal === 'string'){
                    let lineBreaks = newVal.split("\n").length;
                    this.input.rows = lineBreaks === 0 ? 1 : lineBreaks;
                    console.log('watch, message', lineBreaks);
                }
            },
        },
        methods: {
            addEmoji({colons, emoticons, id, name, native, skin, unified }) {

                // TODO fix multiple emojis in row

                // insert at cursor position, nothing selected, then automatically insert at end
                let cursorPosition = this.$refs.elMessage.selectionStart;
                this.input.text    = this.input.text.slice( 0, cursorPosition )
                    + native // TODO native versus unified
                    + this.input.text.slice( cursorPosition );
                // set focus to textarea
                this.$refs.elMessage.focus();
                // reset to old position
                this.$nextTick(()=>this.$refs.elMessage.setSelectionRange(cursorPosition+1,cursorPosition+1));
            },
            async sendMessage($event){
                if(!$event.shiftKey){ // not shift-enter for line-break
                    if(this.isMessage()){
                        let messageAndUser = {user: this.getUser(), message: this.getMessage()};
                        await this.sendMessageBySocialize(messageAndUser, ({ success }) => success
                            ? this.messageAndUser[this.messageAndUser.length-1]['saving'] = false
                            : console.log('saving news, no success')
                        );
                        this.addMessageAndUser(messageAndUser);
                        this.clearMessage();
                        this.$nextTick( this.scrollToBottom )
                    }
                }
            },
            isMessage(){
                return _.trim(this.input.text).length;
            },
            getUser(){
                let profile = _.get(this.user, 'profile', '');
                profile = profile.replace(window.location.origin, ''); // remove base-url

                return {
                    id      : _.get(this.user, 'id'),
                    name    : _.get(this.user, 'name'), // 'Max Mustermann'
                    profile : profile, // '/user/Max-Mustermann-0',
                    image   : _.get(this.user, 'image', ''), // empty for saving, loading using default-no-image-placeholder
                    premium : _.get(this.user, 'premium', false), //false,
                };
            },
            addMessageAndUser(messageAndUser)
            {
                this.messageAndUser.push(_.merge({
                    saving : true // show disc-symbol for saving
                }, messageAndUser))
            },
            getMessage(){
                return {
                    id       : this.entity.id,
                    userId   : this.user.id,
                    timestamp: new Date().getTime(), // new Date @ 13 position,  moment().unix() @ 10 positions (max diff second)
                                                     // need to use 13 position, milliseconds, otherwise elastic index not unique by timestamp in seconds only, so crashes on insert same (user, breakingNews, timestamp) again
                    text     : _.trim( this.input.text ),
                }
            },
            clearMessage(){
                this.input.text = '';
            },
            getWindow()
            {
                return window;
            },
            on({hasElements = false, hasHeight = false, inView = false, fireOnce = true, events = [
                'onload', // page loaded
                'scroll', // window scrolled (no click to show, already opened)
                'resize', // mobile landscape, portrait
                'click',  // bootstrap tab clicked
            ] }, cbTrue)
            {
                let eventListenerOptions = {useCapture: false, passive: true};

                events.forEach((event,i)=>{

                    let fnEvent = ()=> { // save in var, to be able to delete later

                        let bool = {
                            hasElements : !hasElements || hasElements && this.messageAndUser.length > 0,
                            hasHeight   : !hasHeight || hasHeight && this.$refs.elChatMessages instanceof Node && this.$refs.elChatMessages.scrollHeight > 0, // only if dom-element is rendered (not hidden), they have a height
                            inView      : !inView || inView && inViewPort(this.$refs.elChatMessages)
                        };

                        if(bool.hasElements && bool.hasHeight && bool.inView){
                            if(fireOnce){
                                window.removeEventListener(event, fnEvent, eventListenerOptions); // remove events
                            }
                            cbTrue({hasElements, hasHeight, inView, events}); // last settings
                        }
                    };

                    window.addEventListener(event, fnEvent, eventListenerOptions); // activate events
                });
            },
            /**
             * scroll to newest message
             */
            scrollToBottom()
            {
                let elem = this.$refs.elChatMessages;
                if(elem instanceof Node){
                    elem.scrollTop = elem.scrollHeight;
                }
            },
            showMoreTextToggle(event, i)
            {
                this.messageAndUser[i].message.textShowFull = !_.get(this, `messageAndUser.${i}.message.textShowFull`, false);

                if ( i + 1 === this.messageAndUser.length ) { // last message
                    this.$nextTick(this.scrollToBottom);
                } else {
                    // keep scrollTop
                }

                // dont trigger "click" bubbling up, which triggers scrollToBottom
                // but this prevents auto re-computed, so call triggerComputed()
                event.stopPropagation();
                this.triggerRerender(); // so trigger re-computed
            },
            /**
             * cause data changed, instead using computed
             */
            triggerRerender()
            {
                this.dataComputedTrigger = !this.dataComputedTrigger;
            },
            deleteMessage(event, iMessage)
            {
                const msg = this.messageAndUser[iMessage];
                Socialize.ready( me => this.socialize.deleteMessage(msg, ({success}) => { // TODO multiple cb assignments, callback multiple times
                    if (success) {
                        this.deleteMessageInChat(msg)
                    } else {
                        console.log('deleteMessage, response, error', response)
                    }
                }));
                event.preventDefault();
            },
            deleteMessageInChat(messageAndUser)
            {
                let index = this.messageAndUser
                    .map((messageAndUserCurr)=> this.getMessageId(messageAndUserCurr) )
                    .indexOf(this.getMessageId(messageAndUser));

                if(index > -1){
                    this.messageAndUser.splice(index, 1); // change original
                }
            },
            getMessageId(messageAndUser)
            {
                messageAndUser = messageAndUser || {};
                let { message : { newsId = 0, userId = 0, timestamp = 0 } } = messageAndUser;

                return [ newsId, timestamp, userId ].join( '_' );
            },
            countEmojis(text, zeroIfText = false)
            {
                return countEmojis(text, zeroIfText);
            },
            getMessagesByPaginationNext(event)
            {
                this.socialize.onGetMessages(null, this.pagination.server); // check, if save on connection-lost (messages not dropped)
                // event.stopPropagation(); // TODO not working, scrolls always down
            },
            initSocialize()
            {
                Socialize.ready((getInstance)=>{
                    let me = this.socialize = getInstance(this.entity);
                    if(me) {
                        me.onError(reason => {
                            this.socket.connection = false;
                            _.forEach(this.socket.error, (v,k) => this.socket.error[k] = false); // reset
                            reason === 'not_authorized'
                                ? this.socket.error.authorization = true
                                : this.socket.error.unknown = true;
                        });
                        me.onConnect(socket => this.socket.connection = true);
                        me.onDisconnect(attempt => this.socket.connection = false);
                        me.onGetMessages( ({data, pagination}) => {
                            // console.log('socialize, onGetMessages',this.entity.id, {data, pagination});

                            // pagination -> show / hide "load older comments button"
                            if(_.get(pagination, 'page.bNext')){
                                const ts = _.get(data, '0.message.timestamp');
                                if(ts){
                                    this.pagination.use = true;
                                    this.pagination.server.range.timestamp.lt = ts;
                                    this.triggerRerender();
                                }
                            }else{
                                this.pagination.use = false;
                            }
                            // update vue-dom
                            data = data.map(v => _.merge({
                                saving: false // show disc-symbol (saving right now)
                            }, v));
                            this.messageAndUser = this.messageAndUser // asc (past to future)
                                    .reverse() // desc (future to past)
                                    .concat(data.reverse()) // desc (future to past) to add at end
                                    .reverse() // asc (past to future)
                            ;
                            // trigger events
                            this.$nextTick( () => {
                                this.scrollToBottom();
                            } );
                        }, this.pagination.server);
                        me.onGetMessageAdded( messageAndUser => {
                            // console.log('SocializeChat, onUpdateNews', messageAndUser);
                            this.messageAndUser.push(messageAndUser);
                        });
                        me.onGetMessageDeleted( messageAndUser => {
                            // console.log('SocializeChat, onMessageDelete', messageAndUser);
                            this.deleteMessageInChat(messageAndUser);
                        })
                    }
                })
            },
            async sendMessageBySocialize(messageAndUser, onAddMessageDone)
            {
                return new Promise((resolve, reject) => {
                    // console.log('SocializeChat, sendMessage', this.getMessage());
                    Socialize.ready(me => {
                        this.socialize.addMessage(messageAndUser, onAddMessageDone);
                        resolve();
                    });
                });
            }
        },
        created(){},
        mounted(){
            this.on({events : ['click'], hasElements: true, hasHeight: true, fireOnce:false}, () => {
                this.scrollToBottom();
                this.visible = true;
            });

            this.initSocialize();
        }
    }
</script>

<style lang="scss" scoped>

    .min-height-messages-window-for-mobile {
        min-height : 20vh;
    }

    .remaining-height {
        position : absolute;
        bottom   : 0;
        top      : 0;
        right    : 0;
        left     : 0;
        overflow : auto;
    }

    /** // vue-transition classes */
    /** @see https://vuejs.org/v2/guide/transitions.html#Transitioning-Single-Elements-Components */
    .fade-enter-active, .fade-leave-active {
        cursor : pointer;
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        cursor : pointer;
        opacity: 0;
    }
</style>