<template>

    <div class="socialize-modal-image-chat">
        <template v-if="itemSelectedNormalized >= 0">

            <transition name="fade" appear>
                <div
                    @click="onClickCloseModal"
                    @touchstart="onMouseDown"
                    @touchmove="onMouseMove"
                    @touchend="onMouseUp"
                    class="modal fade show modal-image-chat overflow-auto"
                    :style="{ display : 'block' }"
                    tabindex="-1" role="dialog" aria-labelledby="gridModalLabel">

                    <!-- left-top-button : facebook share -->
                    <div class="d-inline-block left-top _text-wd-gray-dark _text-wd-white--hover pl-25px pt-25px d-flex">
                        <a v-if="items[itemSelectedNormalized]['uriShareFacebook']"
                           target="_blank"
                           style="min-width:40px"
                           class="d-flex align-items-center line-height-26px pr-0 bg-black rounded-10px opacity-70 h-40px mr-8px"
                           :href="items[itemSelectedNormalized]['uriShareFacebook']"
                        >
                            <span class="d-inline-block wd-icon wd-icon-facebook mdi-middle font-size-18px d-flex align-items-center pl-sm-14px px-12px"></span>
                            <span class="d-none d-md-inline-block font-size-15px pr-14px">{{ $t('common.to_share') }}</span>
                        </a>
                        <a target="_black"
                           class="d-flex align-items-center line-height-26px bg-black rounded-10px opacity-70 h-40px"
                           @click="onClickFullScreen"
                           style="min-width:40px"
                           :href="'#'"
                        >
                            <span :class="[
                                    'd-flex align-items-center',
                                    'd-inline-block wd-icon wd-icon-resize-square font-size-18px px-12px pl-sm-14px',
                                ]"></span>
                            <span class="d-none d-md-inline-block font-size-15px pr-14px">{{ $t('common.fullscreen') }}</span>
                        </a>
                    </div>

                    <!-- center-top button : close -->
                    <div class="w-100 text-center position-relative" style="z-index: 1">
                        <div class="d-inline-block text-wd-gray-dark text-wd-white--hover px-25px pt-25px">
                            <div class="d-flex align-items-center close-modal text line-height-26px bg-black rounded-10px opacity-70 h-40px">
                               <span class="d-inline-block font-size-15px pl-14px pr-14px pr-sm-0">{{itemSelectedNormalized+1}} {{ $t('common.from')}} {{items.length}} </span>
                                <span class="d-none d-sm-inline-block font-size-15px pl-3px pr-14px"> {{$t('common.images')}}</span>
                            </div>
                        </div>
                    </div>

                    <!-- right-top button : close -->
                    <div class="d-inline-block right-top text-wd-gray-dark text-wd-white--hover pr-25px pt-25px" >
                        <a class="d-flex align-items-center close-modal text line-height-26px bg-black rounded-10px opacity-70 h-40px"
                           href="#"
                           style="min-width:40px"
                           @click.prevent.stop="onClickCloseModal('close')"
                        >
                            <span class="d-inline-block mdi mdi-close mdi-middle font-size-18px d-flex align-items-center pl-sm-14px px-12px"></span>
                            <span class="d-none d-md-inline-block font-size-15px pr-14px">{{ $t('forum.close') }}</span>
                        </a>
                    </div>

                    <div class="modal-dialog modal-dialog-centered justify-content-center position-relative" role="document"
                         :style="{ 'max-width':'unset', }">

                        <!-- slider left -->
                        <div v-if="items.length > 1" class="slider left d-sm-inline-flex" :class="{'d-none': hasTouch}">
                            <a href="#" @click.prevent.stop="onClickSlideLeft">
                                <span class="wd-icon wd-icon-arrow24 rounded-50 border-5px font-size-22px ml-25px p-10px rounded-50p" style=" border: solid 2px;"></span>
                            </a>
                        </div>

                        <!-- content -->
                        <div class="overflow-hidden">
                            <div class="modal-content position-relative">

                                <div class="modal-body p-0">

                                    <!-- loading animation -->
                                    <div ref="loading" class="spinner-rect-5 loading">
                                        <div class="rect1"></div>
                                        <div class="rect2"></div>
                                        <div class="rect3"></div>
                                        <div class="rect4"></div>
                                        <div class="rect5"></div>
                                    </div>

                                    <!-- v-if="items[itemSelected] && typeof items[itemSelected] === 'string'" -->
                                    <div v-if="!waiting"
                                         class="container-fluid d-flex flex-column flex-md-row px-0"
                                    >

                                        <!-- image on left side -->
                                        <a v-lazy-container="{ selector : 'img' }"
                                           ref="shadow"
                                           :class="[
                                                'd-flex align-items-center justify-content-center position-relative shadow-trigger image__container rounded-10px',
                                                !!items[itemSelectedNormalized].title ? '' : 'shadow-hidden'
                                           ]"
                                           :style="{
                                                'max-height': '100%',
                                                'overflow'  : 'hidden' // dont show blur
                                            }"
                                           :href="items[itemSelectedNormalized].href"
                                           :title="items[itemSelectedNormalized].title"
                                           @mousemove="setAnimationShadow"
                                        >
                                            <div class="shadow top"></div>
                                            <div class="shadow title text-truncate p-1 p-md-4">{{ items[itemSelectedNormalized].title }}</div>
                                            <img :alt="items[itemSelectedNormalized].title"
                                                 :data-src="bFullscreen
                                                 ? items[itemSelectedNormalized]['uriFullscreen']['uri']
                                                 : items[itemSelectedNormalized]['uri']['uri']"
                                                 class="blur position-absolute"
                                                 @contextmenu.prevent="preventRmbMenu"
                                            />
                                            <img :alt="items[itemSelectedNormalized].title"
                                                 :data-src="bFullscreen
                                                 ? items[itemSelectedNormalized]['uriFullscreen']['uri']
                                                 : items[itemSelectedNormalized]['uri']['uri']"
                                                 class="img-fluid"
                                                 style="z-index: 1;"
                                                 @contextmenu.prevent="preventRmbMenu"
                                                 @loading="onImageLoading($event, 'loading')"
                                                 @loaded="onImageLoading($event, 'loaded')"
                                                 @error="onImageLoading($event, 'error')"
                                            />
                                            <div class="shadow bottom"></div>
                                        </a>

                                        <!-- white text block on right side -->
                                        <div
                                            v-if="socialize.aEntities.length"
                                            class="px-0 d-flex flex-column bg-white news-and-chat"
                                        >
                                            <slot name="top-right"></slot>
                                            <template v-for="(entity, i) in socialize.aEntities">
                                                <!-- initialize all, but show only 1 -->
                                                <!-- so no re-render is necessary -->
                                                <div :class="[ i === itemSelectedNormalized ? 'd-flex' : 'd-none', 'flex-grow-1' ]">
                                                    <vue-chat
                                                        :user="socialize.oUser"
                                                        :fallback="socialize.fallback"
                                                        :entity="entity"
                                                        :overlay="true"
                                                    ></vue-chat>
                                                </div>
                                            </template>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- slider right -->
                        <div v-if="items.length > 1" class="slider right d-sm-inline-flex" :class="{'d-none': hasTouch}" @click.prevent.stop="onClickSlideRight">
                            <a href="#">
                                <span class="wd-icon wd-icon-arrow3 rounded-50 border-5px font-size-22px mr-25px p-10px rounded-50p" style=" border: solid 2px;"></span>
                            </a>
                        </div>

                    </div>
                </div>

            </transition>

            <transition name="fade" appear>
                <div class="modal-backdrop fade show background"></div>
            </transition>

        </template>
    </div>

</template>

<script>
import _throttle      from 'lodash/throttle';
import _get           from 'lodash/get';
import _isPlainObject from 'lodash/isPlainObject';

import Chat           from './socialize/Chat.vue';
import Mogile         from './../../js/layout/wd18774/Mogile';

export default {
    components : {
        'vue-chat' : Chat
    },
    props: {
        modalOpenSelector : {
            type    : String,
            required: false,
            default : '', // .images-<hash> .image-tile-layout > a > div
            validator( value ) {
                return true; // TODO
            }
        },
        items: {
            type    : Array,
            required: true,
            default : [], // { href, title, uri }
            validator( value ) {
                return true; // TODO
            }
        },
        lazyLoadAmount: {
            type    : Number,
            required: false,
            default : 3,
            validator( value ) {
                return true; // TODO
            }
        },
        socialize: {
            type     : Object,
            required : false,
            default() {
                return {
                    aEntities: [], // { id : 1, type : 'image' }, ... { id : 9999, type : 'image' }
                    fallback : {
                        user: {
                            image  : "/static/images/placeholder/v3/no_user_v3_100_100.png",
                            name   : "",
                            premium: false,
                            profile: "",
                        }
                    },
                    oUser    : {
                        canDelete: false,
                        id       : 0,
                        image    : "",
                        name     : "",
                        premium  : false,
                        profile  : "/user/firstname-lastname-0"
                    }
                };
            },
            validator( value ) {
                return true; // TODO
            },
        },
    },
    data() {
        return {
            itemSelected : -1,
            bFullscreen  : false,
            waiting      : false,
            animate      : true,
            animation    : 'animation fadeInRight',
            mouseDownPositionX : null,
            mouseDownPositionY: null,
            isSwiping: false,
            swipeDistanceX: null,
            hasTouch: ('ontouchstart' in window),
        }
    },
    computed: {
        itemSelectedNormalized: function(){
            if(this.itemSelected >= 0) // not -1
            {
                // only request, if current image has to be loaded
                // to avoid load next -> fetches always 3, would always fire ajax
                const hashToImageSelected = _get(this.items, this.itemSelected+'.uri');
                const bMogile = _isPlainObject(hashToImageSelected);

                if(bMogile){
                    const from = this.itemSelected;
                    const to   = Math.min(this.itemSelected + this.lazyLoadAmount-1, this.items.length-1);

                    let aHashToImageByMogile = [];

                    let aUri_Index = [];
                    for(let i = from; i<=to; i++)
                    {
                        const hashToImage = _get(this.items, i+'.uri');
                        if(_isPlainObject(hashToImage) && !_get(hashToImage, 'uri'))
                        {
                            aHashToImageByMogile.push(hashToImage);
                            aUri_Index.push({iItem : i, iMogile : aHashToImageByMogile.length-1});
                        }
                    }

                    let aUri_IndexFullscreen = [];
                    for(let i = from; i<=to; i++)
                    {
                        const hashToImageFullScreen = _get(this.items, i + '.uriFullscreen');
                        if (_isPlainObject(hashToImageFullScreen) && !_get(hashToImageFullScreen, 'uri'))
                        {
                            aHashToImageByMogile.push(hashToImageFullScreen);
                            aUri_IndexFullscreen.push({iItem : i, iMogile : aHashToImageByMogile.length-1});
                        }
                    }

                    if(aHashToImageByMogile.length)
                    {
                        // console.log('ModalImageChat : mogile -> fetchUrl');
                        this.waiting = true;
                        Mogile.fetchUrls({aMogileParameters : aHashToImageByMogile}).then((images) =>{
                            // console.log('ModalImageChat : mogile -> fetchUrl -> response', images);
                            if(images.length !== aUri_Index.length + aUri_IndexFullscreen.length){
                                console.log( // keep this!
                                    'ModalImageChat, error : MogileFetch-image-amount != requested',
                                    {
                                        aHashToImageByMogile,
                                        aUri_Index,
                                        aUri_IndexFullscreen,
                                        mogileResponse : images
                                    }
                                );
                            }
                            aUri_Index.forEach((o, iIterate)=>{
                                const {iItem, iMogile} = o;
                                this.items[iItem]['uri']['uri'] = _get(images, iMogile, this.items[iItem]['uri']);
                            });
                            aUri_IndexFullscreen.forEach((o, iIterate)=>{ // currValue, Index, Array
                                const {iItem, iMogile} = o;
                                this.items[iItem]['uriFullscreen']['uri'] = _get(images, iMogile);
                            });
                            this.waiting = false;
                        });
                    }
                }
            }

            return this.itemSelected;
        }
    },
    watch: {},
    methods: {
        close(){
            this.resetFullScreen();
            this.itemSelected = -1;
        },
        onClickSlideLeft(event)
        {
            this.resetFullScreen();

            let itemBefore = this.itemSelected-1,
                aboveZero  = itemBefore + this.items.length,
                result     = (aboveZero) % (this.items.length);

            this.itemSelected = result;

            this.onImageLoading(null, 'loading'); // show loading-css-animation earlier
        },
        onClickSlideRight(event)
        {
            this.resetFullScreen();

            let itemAfter  = this.itemSelected+1,
                aboveZero  = itemAfter + this.items.length,
                result     = (aboveZero) % (this.items.length);

            this.itemSelected = result;

            this.onImageLoading(null, 'loading'); // show loading-css-animation earlier
        },
        onClickCloseModal(event)
        {
            if (this.isSwiping) {
                return false;
            }
            if( event === 'close' ||
                typeof event === 'object' && ( // clicked background
                event.target.getAttribute('role') === 'dialog' ||
                event.target.getAttribute('class').includes('close')
                )
            ) {
                // setTimeout(() => document.body.classList.remove('overflow-hidden'), 200); // animation-delay
                this.close();
            }
        },
        normalizeTouch(e) {
            return (e.changedTouches ? e.changedTouches[0] : e);
        },
        onMouseDown(e) {
            if (this.items.length === 0) {
                return;
            }
            if (e.changedTouches && e.changedTouches.length > 1) {
                this.mouseDownPositionX = null;
                this.mouseDownPositionY = null;
                this.isSwiping = false;
                return
            }
            this.mouseDownPositionX = this.normalizeTouch(e).clientX;
            this.mouseDownPositionY = this.normalizeTouch(e).clientY;
        },
        onMouseMove(e) {
            if (this.mouseDownPositionX) {
                this.swipeDistanceX = this.mouseDownPositionX - this.normalizeTouch(e).clientX;
                const swipeDistanceY = this.mouseDownPositionY - this.normalizeTouch(e).clientY;
                this.isSwiping = (Math.abs(swipeDistanceY) < Math.abs(this.swipeDistanceX)) && Math.abs(this.swipeDistanceX) > 10;
            }
        },
        onMouseUp(e) {
            if (this.isSwiping) {
                if (this.swipeDistanceX > 0) {
                    this.onClickSlideRight(e);
                } else {
                    this.onClickSlideLeft(e);
                }
            }
            this.mouseDownPositionX = null;
            this.mouseDownPositionY = null;
            setTimeout(() => {
                this.swipeDistanceX = null;
                this.isSwiping = false;
            }, 400);
        },
        onClickShowModal()
        {
            if(this.modalOpenSelector.length)
            {
                let nodes = document.querySelectorAll(this.modalOpenSelector);

                if(this.items.length > 0 && nodes.length !== this.items.length){
                    console.log('warning - ModalImageChat.vue, onClickShowModal, items !== selector', {
                        items : this.items, selector : this.modalOpenSelector, nodes : nodes
                    })
                }

                nodes.forEach((elem,i) => elem.addEventListener('click', ()=>{

                    // console.log('ModalImageChat', { // debug
                    //     nodes,
                    //     modalOpenSelector : this.modalOpenSelector,
                    //     selection : i,
                    // });

                    this.itemSelected = i;
                    // document.body.classList.add('overflow-hidden');
                }, false));
                if(!nodes.length){
                    console.log('ModalImageChat, imageSelector : no dom elements found', {selector : this.modalOpenSelector});
                }
            }else{
                console.log('ModalImageChat, imageSelector : empty')
            }
        },
        setAnimationShadow : _throttle(function(event){
            if (this.$refs.shadow) // fix on closing modal
            {
                this.$refs.shadow.classList.remove('shadow-trigger');
                this.$refs.shadow.offsetWidth; /** @see https://css-tricks.com/restart-css-animation/ */
            this.$refs.shadow.classList.add('shadow-trigger');
            }
        }, 500), // needs to be the same as .shadow-out @ transition-delay
        onImageLoading(event, state)
        {
            if(['error','loaded'].includes(state)) {
                this.$refs.loading.classList.add('loading-done');
            }else{ // loading
                this.$refs.loading.classList.remove('loading-done');
            }
        },
        onClickFullScreen(event)
        {
            this.toggleFullScreen();
            event.preventDefault();
            event.stopPropagation();
        },
        resetFullScreen()
        {
            this.bFullscreen = false;
        },
        toggleFullScreen()
        {
            this.bFullscreen = !this.bFullscreen;
        },
        preventRmbMenu(e){
            e.preventDefault();
        }
    },
    created(){},
    mounted(){
        this.onClickShowModal();
    }
}
</script>

<style lang="scss" scoped>
.modal-content {
    background: none !important;
}
.slider {
    color          : #707070;
    font-weight    : bold;
    font-size      : 5em;
    pointer-events : auto; /* to register auto-clicks */

    &.left {
        position : fixed !important; /* image to large, always vertical centered */
        left     : 0;
        top      : 50%;
        transform: translateY(-50%);
        z-index  : 3; /* visible above image */

        border-radius: 50px;
        line-height  : 1em;
        /*background   : rgba(0, 0, 0, 0.05);*/ /* opacity like without "opcatiy :value" on whole content */
    }
    &.right {
        position : fixed !important; /* image to large, always vertical centered */
        right    : 0;
        top      : 50%;
        transform: translateY(-50%);
        z-index  : 3; /* visible above image */

        border-radius: 50px;
        line-height  : 1em;
        /*background   : rgba(0, 0, 0, 0.05);*/ /* opacity like without "opcatiy :value" on whole content */
    }
}
.background {
    background-color : black !important;
    opacity          : 0.8 !important;
    height: -webkit-fill-available;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.slide-enter-active, .slide-leave-active {
    transition: opacity 0.5s;
}
.slide-enter, .slide-leave-to {
    opacity: 0;
}

.image__container {
    pointer-events: none; // prevent a (link) image-click
}

img {
    &[lazy=loading] {
        background : none; /* rgba(0, 0, 0, 0.05); */
    }
    &[lazy=loaded], &[lazy=error] {
        background : black;
        transition : background 0.5s;
        width      : 100%; /* ie max-width not working, needs width first */
    }
    &.blur {
        filter         : blur(12px) brightness(200%) opacity(50%);
        -webkit-filter : blur(12px) brightness(200%) opacity(50%);
        /*transform      : scale(1.25);*/ /* deactivated, overlaps with message area */

        width    : 100%;
        height   : 100%;

        &[lazy=loading] {
            filter     : none;
        }
    }
}

.loading {
    position       : fixed;
    z-index        : 2;
    top            : 50%;
    transform      : translate(-50%, -50%);
    left           : 50%;

    background     : black;
    opacity        : 0.5;
    border-radius  : 10px;
    padding-top    : 10px;
    height         : 70px;
    padding-bottom : 10px;

    &-done {
        display : none;
    }
}


div.left-top, div.right-top {
    position    : absolute;
    top         : 0;
    z-index     : 3; /* 2 = above image, 1 : above text-block, 3 = above shadow */

    a {
        color : var(--wd-gray-dark);
        &:hover {
            color : var(--white);
        }
    }
}
div.left-top {
    left: 0;
}
div.right-top {
    right: 0;
}

a.close-modal {
    font-weight : bold;
    font-size   : 2.5rem;
    //color       : #999;
    line-height : 1em;
}

.mdi:hover {
    //color      : white;
    opacity    : 1;
    transition : opacity 250ms, color 250ms;
}

.shadow {
    position   : absolute;
    width      : 100%;
    left       : 0;
    box-shadow : 0 0 50px 50px rgba(56, 56, 56, 0.75) !important;
    z-index    : 2;
    opacity    : 1;

    &-hidden {
        .shadow.top, .shadow.bottom, .title {
            display: none;
        }
    }

    &-trigger {
        .shadow.top, .shadow.bottom, .title {
            opacity          : 0;
            transition       : opacity 1s;
            transition-delay : 500ms;
        }
    }

    &.top {
        top        : 0;
    }
    &.bottom {
        bottom     : 0;
    }

    &.title {
        top           : 0; // cant use %, cause higher than wider -> not same distance
        left          : 0; // cant use %, cause higher than wider -> not same distance
        box-shadow    : none !important;
        color         : white;
        max-width     : calc(100%); // 100% - left - right
        font-size     : 2rem;
        line-height   : 2.5rem; // > font-size, so not cut off
    }
}
</style>