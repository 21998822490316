class Json {

    static decode(v)
    {
        let result = v;
        try {
            result = typeof v === 'string' && v[0] === '{' ? JSON.parse(v) : v;
        }catch(e){
            // not valid json
        }

        return result;
    }

}

export default Json;