<template>
    <div>

        <!-- desktop -->
        <div class="d-flex flex-lg-wrap flex-md-nowrap flex-sm-nowrap flex-sx-nowrap p-b-4 mb-4 overflow-hidden"
             v-if="!mobileView"
        >
            <div class="flex overflow-hidden"
                 v-for="(document, index) in aDocuments"
                 :key="`document-${index}`"
            >
                <vue-dog-doc-list-item :a-document="document"></vue-dog-doc-list-item>
            </div>
        </div>

        <!-- mobile -->
        <div class="slides-container"
             v-else
        >
            <div class="slide-navigation">
                <a class="prev" @click.prevent="prev" href="#"><span class="mdi mdi-arrow-left"></span></a>
                <a class="next" @click.prevent="next" href="#"><span class="mdi mdi-arrow-right"></span></a>
            </div>
            <transition-group name="fade" class="slide-transition-group" tag="div">
                <div v-for="(document, index) in aDocuments" :key="`document-${index}`">
                    <vue-dog-doc-list-item :a-document="document"></vue-dog-doc-list-item>
                </div>
            </transition-group>
        </div>

    </div>
</template>

<script>
import DogDocListItem  from './DogDocListItem.vue';

export default {
    components: {
        'vue-dog-doc-list-item' : DogDocListItem
    },

    props: {
        aData: {
            type    : Array,
            required: true,
        },
    },

    data() {
        return {
            aDocuments: this.aData,
        }
    },

    methods: {
        next() {
            const first = this.aDocuments.shift();
            this.aDocuments = this.aDocuments.concat(first);
        },
        prev() {
            const last = this.aDocuments.pop();
            this.aDocuments = [last].concat(this.aDocuments);
        },
        handleView() {
            this.mobileView = window.innerWidth <= 1009; //from md downward
        },
    },
    created() {
        this.handleView();
        window.addEventListener("resize", this.handleView);
    },
    mounted() {
        let sliderjs = document.createElement('script')
        sliderjs.setAttribute('src', 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js')
        document.head.appendChild(sliderjs)
    },
};
</script>

<style lang="scss" scoped>
    .slides-container {
        display: flex;
        flex-direction: column;

        .slide-transition-group {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            // padding-left: 80px;
            padding-bottom: 30px;
        }
    }

    .slide-navigation {
        display: flex;
        justify-content: flex-end;
        margin-top: -60px;
        margin-bottom: 60px;
        .mdi-arrow-left {
            margin-right: 25px
        }
    }
</style>
