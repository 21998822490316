class MainPictureOverlay
{
    constructor({sClassName}) {

        this._iAnimalID = null;
        this._iTimerID = null;
        this._sCurrentAjax = null;
        this._iMousePosX = 0;
        this._iMousePosY = 0;
        this._oThis = null;
        this._iDistance = 10;

        this._iClientX = 0;
        this._iClientY = 0;
        this._iPageX = 0;
        this._iPageY = 0;

        this._iScreenWidth = 0;
        this._iScreenHeight = 0;

        this._stopMove = false;

        this._createWaitingAnimation();
        this._getSystemCoords();
        this._setEventOnElements( sClassName );

    }


    _setEventOnElements( sSelector )
    {
        const _this = this;
        $( "body" )
            .on( "mouseover", sSelector, function () {
                _this._oThis = $( this );

                _this._iTimerID = setTimeout( function () {
                    _this._handleDisplayingWaitingAnimation( true );
                    _this._iAnimalID = _this.getAnimalId();

                    if ( _this._iAnimalID < 1 ) {
                        _this._handleDisplayingWaitingAnimation( false );
                        return false;
                    }
                    _this._stopMove = false;
                    _this._prepareOverlay();
                }, 200 );
            } )
            .on( "mouseout", sSelector, function () {
                _this._abortAjax();
                _this._handleDisplayingWaitingAnimation( false );
                _this._handleDisplayingOverlay();

            } );
    }

    _prepareOverlay()
    {
        const _this = this;
        var $oElement = $( 'body > .pictureOverlay#animal_' + _this._iAnimalID );
        if ( $oElement.length < 1 ) {
            _this._startAjax();
        } else {
            _this._showOverlay( $oElement );
        }
    }

    _startAjax()
    {
        const _this = this;

        var $oOverlay = _this._oThis.find( "div.pictureOverlay" );
        var $oImg = $oOverlay.find( "img.overlayPic" );
        var iId = $oImg.data( "aid" );
        if ( typeof iId == "undefined" ) {
            return;
        }
        _this._sCurrentAjax = $.get( "/animal/view-v3/mainpic-v3", { id: iId }, function ( data ) {
            $oImg
                .attr( "src", data )
                .load( function () {
                    var oImage = $oImg[0];
                    $oOverlay.width( oImage.naturalWidth );
                    $( 'body' ).append( $oOverlay );
                    var $oElement = $( '.pictureOverlay#animal_' + _this._iAnimalID );
                    _this._showOverlay( $oElement );
                } );
        }, 'text' );
    }

    _showOverlay( $oElement )
    {
        const _this = this;

        _this._handleDisplayingOverlay( $oElement );
        _this._oThis.mousemove( function () {
            if ( !_this._stopMove ) {
                _this._calcPosition( $oElement );
                $oElement.css( 'top', _this._iMousePosY );
                $oElement.css( 'left', _this._iMousePosX );
            }
        } );
    }

    _handleDisplayingOverlay( $oElement )
    {
        const _this = this;

        if ( typeof $oElement == 'undefined' || $oElement.length == 0 ) {
            _this._stopMove = true;
            $( '.pictureOverlay' ).hide();
            if ( _this._oThis ) {
                _this._oThis.unbind('mousemove');
            }
        } else {
            _this._handleDisplayingWaitingAnimation( false );
            _this._calcPosition( $oElement );
            $oElement.css( 'top', _this._iMousePosY );
            $oElement.css( 'left', _this._iMousePosX );
            $oElement.fadeIn( 300 );
        }
    }

    _abortAjax()
    {
        const _this = this;
        clearTimeout( _this._iTimerID );

        if ( _this._sCurrentAjax ) {
            _this._sCurrentAjax.abort();
        }
    }

    _calcPosition( $oElement )
    {
        const _this = this;
        var iBoxWidth = $oElement.width();
        var iBoxHeight = $oElement.height();

        _this._iMousePosX = _this._iPageX + _this._iDistance;
        _this._iMousePosY = _this._iPageY + _this._iDistance;

        if ( _this._iScreenWidth - iBoxWidth < _this._iClientX ) {
            _this._iMousePosX -= iBoxWidth + 20 + _this._iDistance;
        }

        if ( _this._iScreenHeight - iBoxHeight < _this._iClientY ) {
            _this._iMousePosY -= iBoxHeight + 15 + _this._iDistance;
        }
    }

    _getSystemCoords()
    {
        const _this = this;
        $( document ).mousemove( function ( e ) {
            _this._iPageX = e.pageX;
            _this._iPageY = e.pageY;
            _this._iClientX = e.clientX;
            _this._iClientY = e.clientY;
            _this._iScreenWidth = $( window ).width();
            _this._iScreenHeight = $( window ).height();
        } );
    }

    _handleDisplayingWaitingAnimation( blShow )
    {
        if ( blShow ) {
            $( '.overlayWaitingImg' ).show();
        } else {
            $( '.overlayWaitingImg' ).hide();
        }
    }

    _createWaitingAnimation()
    {
        const _this = this;

        if ( $( "body > .animalOverlay.overlayWaitingImg" ).length < 1 ) {
            $( 'body' ).append( '<img class="animalOverlay overlayWaitingImg" src="' + window.IMAGE_CDN_URL + 'animations/wait_16x16.gif" alt="" />' );
            $( document ).mousemove( function ( e ) {
                $( '.overlayWaitingImg' ).css( 'top', e.pageY + _this._iDistance + 3 ).css( 'left', e.pageX + _this._iDistance + 3 )
            } );
        }
    }

    getAnimalId()
    {
        const _this = this;

        var sId = _this._oThis.attr( "id" );
        console.log('ANIMAL ID' + sId);
        if ( sId ) {
            return parseInt( sId.replace( "animal_", "" ) );
        } else {
            return 0;
        }
    }

}

export default MainPictureOverlay;