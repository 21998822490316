<template>
        <div class="breed-section">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mt-3 mb-2">
                    <span class="bg-wd-green text-white py-1 px-2 mr-2 font-weight-bold" style="font-size: 16px; border-radius:6px;">{{ year }}</span> <span class="text-wd-green font-weight-bold" style="font-size: 20px">{{ title }}</span>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-7 mt-3 mb-2">
                    <div class="row d-flex">
                        <div class="col-xs-12 col-sm-4 col-md-12 col-lg-4 pt-3">
                            <div class="row flex-nowrap">
                                <div class="d-md-none mt-3 text-wd-menu-shadow text-nowrap">
                                    {{ breeder }}
                                </div>
                                <div class="flex-row">
                                    <img :src="breedprofileURL" width="50px" height="50px" class="rounded-circle mr-3 ml-3 ">
                                </div>
                                <div class="flex-column flex-nowrap mt-2">
                                    <div class="text-nowrap font-weight-bold">{{ name }}</div>
                                    <div class="text-wd-gray-dark text-nowrap"><span class="mdi mdi-cellphone text-menu-shadow"></span>{{ tel }}</div>
                                </div>
                            </div>
                            <div class="d-none d-md-block col-md-6 col-lg-6 mt-3 text-wd-menu-shadow text-nowrap">
                                {{ breeder }}
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 d-inline-flex pt-3">
                            <div class="d-inline-flex mb-4 col-sm-5 col-md-auto">
                                <vue-breed-date
                                    :day="matingDay" :month="matingMonth" caption="Decktag"
                                ></vue-breed-date>

                                <vue-breed-date
                                    :day="litterDay" :month="litterMonth" caption="Wurftag"
                                ></vue-breed-date>
                            </div>
                            <div class="text-center mr-3">
                                <vue-breed-chart
                                    :progress="progress" caption="Wurfstärke"
                                ></vue-breed-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-5">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                            <img :src="imageDogURL" class="rounded img-cover">
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 pt-3 mb-3">
                            <div class="mt-2 text-nowrap"><span class="font-weight-bold" style="font-size: 16px">{{ clubName }}</span><span class="mdi mdi-gender-male text-wd-dark-sky-blue"></span></div>
                            <div class="mt-2"><span class="text-menu-shadow">{{ born }}:</span> {{ bornDate }}</div>
                            <div class="mt-2"><span class="text-menu-shadow">{{ trainingIndicator }}:</span> {{ trainingIndicatorName }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pt-4 border-top">
                <div class="text-center text-menu-shadow"><a href="#">{{ morebtn }}</a> <span class="mdi mdi-chevron-right text-menu-shadow"></span></div>
            </div>

        </div>
</template>

<script>
// import _throttle      from 'lodash/throttle';
// import _get           from 'lodash/get';
import _isPlainObject from 'lodash/isPlainObject';
import BreedDate from './Date.vue';
import BreedChart from './Chart.vue';

export default {
    components : { // use sub-components ?
        // 'vue-component-x' : 'importedComponent'
        'vue-breed-date' : BreedDate,
        'vue-breed-chart': BreedChart
    },
     props: {
        year: {
            type    : Number,
            required: true,
        },
        title: {
            type    : String,
            required: true,
        },
        name: {
            type    : String,
            required: true,
        },
        tel: {
            type    : Number,
            required: true,
        },
        breeder: {
            type    : String,
            required: true,
        },
        breedprofileURL: {
            type    : String,
            required: true,
        },
        progress: {
            type    : String,
            required: true,
        },
        imageDogURL: {
            type    : String,
            required: true,
        },
        clubName: {
            type    : String,
            required: true,
        },
        born: {
            type    : String,
            required: true,
        },
        bornDate: {
            type    : String,
            required: true,
        },
        trainingIndicator: {
            type    : String,
            required: true,
        },
        trainingIndicatorName: {
            type    : String,
            required: true,
        },
        morebtn: {
            type    : String,
            required: true,
        },
        matingMonth: {
            type    : String,
            required: true,
        },
        matingDay: {
            type    : String,
            required: true,
        },
        litterMonth: {
            type    : String,
            required: true,
        },
        litterDay: {
            type    : String,
            required: true,
        },
    },
    computed: {},
    watch: {},
    methods: {

    },
    created(){},
    mounted(){
        // ...init javascript on vue-creation...
    }
}
</script>

<style lang="scss" scoped>
.img-cover{
    object-fit: cover;
    width:158px; 
    height:106px; 
}
@media only screen and (max-width: 575px) {
    .img-cover{
        object-fit: cover;
        width:100%; 
        height:225px; 
    }
}
</style>
