// import Repository from './repository/User'; // TODO use for caching, same news multiple times
import io      from 'socket.io-client'; /** @see https://socket.io/docs/client-api/ */
import {ready} from './../../../ready.js';
import axios   from 'axios'; // TODO check

let _storeByAmqpViaWdUrl = null,
    _socket              = null,
    _connect             = false;

class Socialize {

    constructor({socket: {url: urlSocket = null} = {}, amqp: { url: urlAmqp = null } = {} } = {}, { id = 0 } ) {
        this._setupSocket = {
            namespace   : 'socialize'.toLowerCase(), // same as nodejs-socialize, ../sockets/<filename> === namespace
            subscription: { // custom event, exists on server-side : name/type/id
                name : 'socialize',
            }
        };

        console.log('socialize, debug', {urlSocket, urlAmqp, id });

        this._entity = {name: this._setupSocket.subscription.name, id}; // specific order
        const room   = _.values(this._entity).join('/');
        this.on      = {
            // without id
            error            : 'error',
            connect          : 'connect',
            disconnect       : 'disconnect',
            subscribe        : [ this._entity.name, 'subscribe' ].join( '/' ), // send id
            // events based on id
            room,
            getMessages      : room + '/getMessages',
            addMessage       : room + '/addMessage',
            deleteMessage    : room + '/deleteMessage',
            getMessageAdded  : room + '/getMessageAdded',
            getMessageDeleted: room + '/getMessageDeleted',
            getMessageCount  : room + '/getMessageCount',
            getViewCount     : room + '/getViewCount',
            addViewCount     : room + '/addViewCount',
        };

        if(urlAmqp){
            _storeByAmqpViaWdUrl = urlAmqp;
        }

        if ( !_socket ) {
            _socket = urlSocket
                ? io( `${urlSocket}/${this._setupSocket.namespace}`, { transports: this.getSocketTransports() } )
                : _socket;
            if(_socket){
                _socket.on(this.on.connect, () => _connect = true);
                _socket.on(this.on.disconnect, () => _connect = false);
            }
        }

        if ( _socket ){
            _socket.on(this.on.error, (reason)=>{
                console.log('Socialize, socket, error', reason);
                try {
                    let json = JSON.parse(reason);
                    if(!json.success && json.error === 'not_authorized'){
                        console.log('not_authorized');
                        // user-feedback on front-end ?
                    }
                }catch(e){ // regular reason-string
                    // ... like 'XHR polling error', if server is offline...
                }
            });
            if(_connect){ // warning : dont call this._subscribe multiple times, otherwise "set"-stuff to server is called multiple times
                this._subscribe(); // right now, fired if connection, fired on after first re-connect
            }else{
                _socket.on(this.on.connect, this._subscribe.bind(this)); // reconnect -> re-subscribe
            }
        }else{
            console.log( 'WARNING - Socialize, socket not initialized' );
        }
    }

    getSocketTransports()
    {
        const query            = 'timeline.socket.transports',
              socketTransports = {
                  default  : ['websocket', 'polling'],
                  polling  : ['polling'],
                  websocket: ['websocket'],   // disabled xhr-polling (no ajax-fallback-usage)
              };

        let useSocketTransports = new URLSearchParams(window.location.search).get(query);
        const isQuery           = !!useSocketTransports;
        useSocketTransports     = Object.keys(socketTransports).includes(useSocketTransports)
            ? socketTransports[useSocketTransports] || socketTransports.default
            : socketTransports.default;

        if(isQuery) {// console.log('socket.io, transports', useSocketTransports); // debug
            console.log('socket, transports using ', useSocketTransports)
        }

        return useSocketTransports;
    }

    _subscribe(socket)
    {
        // console.log('Socialize, subscribe, emit : ', { namespace : _socket.nsp, event : this.on.subscribe }, 'data', { ...this._entity} );
        _socket.emit(this.on.subscribe, this._entity );
    }

    async _storeByAmqpViaWd(data)
    {
        if(_storeByAmqpViaWdUrl){
            // send as json (so NOT via jquery) to keep data-types ()
            // on jquery.post -> json is send as query-string, so data-types are not the same, e.g: number in backend will be string
            const response = await axios.post(_storeByAmqpViaWdUrl , data);
            console.log('socialize, _storeByAmqpViaWd', data);
        }
    }

    // =================================================================================================================
    //  common
    // =================================================================================================================

    onError(cbReceive)
    {
        if(cbReceive){
            _socket.on(this.on.error, (reason)=>{

                // TODO if message sent, when server has error -> on error on client -> fetch messages since "latest timestamp" on client

                try {
                    let json = JSON.parse(reason);
                    if(!json.success && json.error === 'not_authorized'){
                        cbReceive(json.error);
                        // user-feedback on front-end ?
                    }else{
                        cbReceive('unknown (custom json, but unknown)')
                    }
                }catch(e){ // regular reason-string
                    // ... like 'XHR polling error', if server is offline...
                    cbReceive(reason);
                }
            });
        }
    }

    onConnect(cbReceive, fireIfAlready = true)
    {
        if(cbReceive){
            _socket.on(this.on.connect, cbReceive); // current false, so will catch fired connect
            if(fireIfAlready && _connect) { // has already connection
                cbReceive();
            }
        }
    }

    onDisconnect(cbReceive, fireIfAlready = true)
    {
        if(cbReceive){
            _socket.on(this.on.disconnect, cbReceive); // for later disconnects
            if(fireIfAlready && !_connect){
                cbReceive();
            }
        }
    }

    // =================================================================================================================
    //  Message
    // =================================================================================================================

    // TODO caching, same unique news id on same page multiple times, save result in cache & fetch vom cache (dont query elastic on same news id again)

    onGetMessages(cbReceive, pagination )
    {
        _socket.emit( this.on.getMessages, pagination );
        cbReceive && _socket.on( this.on.getMessages, (...args) => {
            console.log('Socialize, onGetNews, received', ...args);
            cbReceive(...args);
        } );
    }

    addMessage(data, cbReceive)
    {
        // console.log('Socialize, setNews', data);
        _socket.emit( this.on.addMessage, data );
        cbReceive && _socket.on( this.on.addMessage, cbReceive );

        this._storeByAmqpViaWd(_.merge({ on : this.on.addMessage }, data));
    }

    onGetMessageAdded(cbReceive)
    {
        cbReceive && _socket.on(this.on.getMessageAdded, cbReceive);
    }

    onGetMessageCount(cbReceive)
    {
        // console.log('Socialize, onGetNewsCount', this.on.count);
        _socket.emit(this.on.getMessageCount);
        cbReceive && _socket.on(this.on.getMessageCount, (...args)=>{
            // console.log('Socialize, onGetNewsCount, received', ...args);
            cbReceive(...args);
        });
    }

    deleteMessage(data, cbReceive)
    {
        _socket.emit( this.on.deleteMessage, data );
        cbReceive && _socket.on( this.on.deleteMessage, cbReceive );

        this._storeByAmqpViaWd(_.merge({ on : this.on.deleteMessage }, data));
    }

    onGetMessageDeleted(cbReceive)
    {
        cbReceive && _socket.on( this.on.getMessageDeleted, cbReceive );
    }

    // =================================================================================================================
    //  view
    // =================================================================================================================

    // TODO caching, same unique news id on same page multiple times, save result in cache & fetch vom cache (dont query elastic on same news id again)

    onGetViewCount(cbReceive)
    {
        _socket.emit(this.on.getViewCount);
        cbReceive && _socket.on(this.on.getViewCount, cbReceive);
    }

    addViewCount(data, cbReceive)
    {
        _socket.emit(this.on.addViewCount, data);
        cbReceive && _socket.on(this.on.addViewCount, cbReceive);

        this._storeByAmqpViaWd(_.merge({ on : this.on.addViewCount }, data));
    }

}

Socialize.prototype.socket = {};

const singleton = (function(){
    let instances = {}, setupData;

    const me = {
        setup : function(setupNew){
            setupData = _.isPlainObject(setupNew) ? setupNew : setupData;
            // console.log('socialize, setup', {setupData, setupNew});
            // debugger;
        },
        ready: (() => {
            let ifReady = ready(
                () => _.isPlainObject( setupData ),
                () => undefined,
                () => me.getInstance
            );
            return ( cb ) => ifReady.then( ( resolvePara ) => cb( resolvePara ) );
        })(),
        getInstance: function({id = 0}) {
            const idValid = _.isNumber(id) && id > 0 || _.isString(id) && id.length;
            if(!idValid){
                console.log('socialize, getInstance : cant create cause type or id empty', {type, id});
                return null;
            }
            let uniqueId = [id].join('/');
            if ( !instances[uniqueId] ) {
                instances[uniqueId] = new Socialize( setupData, {id} );
            }
            return instances[uniqueId];
        }
    };

    return me;
})();

export default singleton;