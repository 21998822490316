<template>
    <div>
        <div class="w-25 slide-navigation">
            <a class="prev" @click.prevent="prev" href="#"><span class="mdi mdi-arrow-left"></span></a>
            <a class="next" @click.prevent="next" href="#"><span class="mdi mdi-arrow-right"></span></a>
        </div>

        <div class="d-flex mt-4 flex-nowrap overflow-hidden">
            <vue-dog-video-list-item v-for="(video, index) in videosProps" :key="`video-${index}`"
                :title="video.title"
                :cover="video.cover"
                :url="video.url"
                :duration="video.duration"
                :views="video.views"
            ></vue-dog-video-list-item>
        </div>
    </div>
</template>

<script>

import DogVideoListItem  from './DogVideoListItem.vue';

export default {
    components: {
        'vue-dog-video-list-item' : DogVideoListItem
    },
    props: {
        videos : {
            type    : Array,
            required: true,
        },
    },

    data() {
        let videosData = {
            videosProps: this.videos,
        }
        return videosData;
    },

    methods: {
    },
    created() {
    },
};
</script>

<style lang="scss" scoped>
    .slide-navigation {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        position: absolute;
        top: 20px;
        right: 20px;
        .mdi-arrow-left {
            margin-right: 15px
        }
    }
</style>
