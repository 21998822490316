export default {
    "cs": {
        "common": {
            "TIME_hour": "#1#ČAS#1# hodin",
            "at_TIME_o_clock": "v #1#TIME#1# hod.",
            "fullscreen": "celá obrazovka",
            "from": "od",
            "videos": "Videa"
        },
        "forum": {
            "close": "Zavřít"
        }
    },
    "da": {
        "common": {
            "fullscreen": "Fuldskærm",
            "from": "af",
            "videos": "Videos"
        },
        "forum": {
            "close": "Luk"
        }
    },
    "de": {
        "chat": {
            "socket_connecting": "verbinde",
            "socket_error_not_authorized": "Authentifizierung fehlgeschlagen",
            "socket_error_unknown": "Fehler",
            "more": "mehr",
            "less": "weniger",
            "delete_message": "Nachricht löschen",
            "read_more": "weiterlesen",
            "show_previous_COUNT_replies": "#1#COUNT#1# vorherige Antworten anzeigen"
        },
        "common": {
            "right_now": "gerade eben",
            "MIN_min": "#1#MIN#1# min",
            "TIME_hour": "#1#TIME#1# Std.",
            "at_TIME_o_clock": "um #1#TIME#1# Uhr",
            "to_share": "Teilen",
            "fullscreen": "Vollbild",
            "from": "von",
            "videos": "Videos",
            "images": "Bilder"
        },
        "community": {
            "no_comments": "Keine Kommentare",
            "add_comment": "Kommentar hinzufügen"
        },
        "emoji": {
            "search": "Suche",
            "notfound": "Kein Emoji gefunden",
            "categories_search": "Suchergebnisse",
            "categories_recent": "Zuletzt verwendet",
            "categories_people": "Smileys",
            "categories_nature": "Tiere & Natur",
            "categories_foods": "Essen & Trinken",
            "categories_activity": "Aktivität",
            "categories_places": "Reisen & Orte",
            "categories_objects": "Objekte",
            "categories_symbols": "Symbole",
            "categories_flags": "Flaggen",
            "categories_custom": "Benutzerdefiniert"
        },
        "forum": {
            "close": "Schließen"
        },
        "movieplayer": {
            "compare": "Video vergleichen",
            "download_2": "Download",
            "video_problems": "Video Störung?",
            "share": "Teilen",
            "facebook": "Facebook",
            "whatsapp": "WhatsApp",
            "email": "Email",
            "embed": "Einbetten",
            "part": "Teil",
            "clipboard": "Zwischenablage"
        }
    },
    "el": {
        "common": {
            "fullscreen": "πλήρης οθόνη",
            "from": "του",
            "videos": "Βίντεο"
        },
        "forum": {
            "close": "Κλείσιμο"
        }
    },
    "en": {
        "chat": {
            "socket_connecting": "connecting",
            "socket_error_not_authorized": "authorization failed",
            "socket_error_unknown": "error",
            "more": "more",
            "less": "less",
            "delete_message": "delete message",
            "read_more": "read more",
            "show_previous_COUNT_replies": "show #1#COUNT#1# previous replies"
        },
        "common": {
            "right_now": "right now",
            "MIN_min": "#1#MIN#1# min",
            "TIME_hour": "#1#TIME#1# hours",
            "at_TIME_o_clock": "at #1#TIME#1#",
            "to_share": "to share",
            "fullscreen": "fullscreen",
            "from": "from",
            "videos": "Videos",
            "images": "Images"
        },
        "community": {
            "no_comments": "no comments",
            "add_comment": "add comment"
        },
        "emoji": {
            "search": "Search",
            "notfound": "No Emoji Found",
            "categories_search": "Search Results",
            "categories_recent": "Frequently Used",
            "categories_people": "Smileys & People",
            "categories_nature": "Animals & Nature",
            "categories_foods": "Food & Drink",
            "categories_activity": "Activity",
            "categories_places": "Travel & Places",
            "categories_objects": "Objects",
            "categories_symbols": "Symbols",
            "categories_flags": "Flags",
            "categories_custom": "Custom"
        },
        "forum": {
            "close": "Close"
        },
        "movieplayer": {
            "compare": "compare movie",
            "download_2": "download",
            "video_problems": "video problems?",
            "share": "Share",
            "facebook": "Facebook",
            "whatsapp": "WhatsApp",
            "email": "Email",
            "embed": "Embed",
            "part": "part",
            "clipboard": "clipboard"
        }
    },
    "es": {
        "common": {
            "fullscreen": "pantalla completa",
            "from": "de",
            "videos": "vídeos"
        }
    },
    "fi": {
        "common": {
            "fullscreen": "Koko näyttö",
            "from": "of",
            "videos": "Videot"
        },
        "forum": {
            "close": "Sulje"
        }
    },
    "fr": {
        "common": {
            "fullscreen": "plein écran",
            "from": "de",
            "videos": "Vidéos"
        },
        "forum": {
            "close": "Près"
        }
    },
    "hu": {
        "common": {
            "fullscreen": "teljes képernyő",
            "from": "mikortól",
            "videos": "Videók"
        },
        "forum": {
            "close": "Zárt"
        }
    },
    "id": {
        "common": {
            "fullscreen": "layar penuh",
            "from": "dari",
            "videos": "Video"
        },
        "forum": {
            "close": "Tutup"
        }
    },
    "it": {
        "common": {
            "fullscreen": "schermo intero",
            "from": "di",
            "videos": "Video"
        },
        "forum": {
            "close": "Chiudi"
        }
    },
    "nb": {
        "common": {
            "fullscreen": "fullskjerm",
            "from": "fra",
            "videos": "Videoer"
        },
        "forum": {
            "close": "Lukk"
        }
    },
    "nl": {
        "common": {
            "fullscreen": "fullscreen",
            "from": "van",
            "videos": "Video's"
        },
        "forum": {
            "close": "Sluiten"
        }
    },
    "pl": {
        "common": {
            "fullscreen": "Pełen ekran",
            "from": "od"
        },
        "forum": {
            "close": "Zamknąć"
        }
    },
    "pt": {
        "common": {
            "from": "de",
            "videos": "Vídeos"
        },
        "forum": {
            "close": "fechar"
        }
    },
    "ro": {
        "common": {
            "fullscreen": "Full screen",
            "from": "de la",
            "videos": "Filmări"
        },
        "forum": {
            "close": "Închide"
        }
    },
    "ru": {
        "common": {
            "fullscreen": "Полноэкранный режим",
            "from": "из",
            "videos": "Видео"
        },
        "forum": {
            "close": "Закрыть"
        },
        "movieplayer": {
            "share": "Поделиться",
            "email": "Email"
        }
    },
    "sk": {
        "common": {
            "from": "od",
            "videos": "Videá"
        },
        "forum": {
            "close": "Zavrieť"
        }
    },
    "sl": {
        "common": {
            "fullscreen": "Celoten zaslon",
            "from": "of",
            "videos": "Video"
        },
        "forum": {
            "close": "Zapri"
        }
    },
    "tr": {
        "common": {
            "fullscreen": "Tam ekran",
            "from": "Kimden",
            "videos": "Videolar"
        },
        "forum": {
            "close": "Kapat"
        }
    },
    "uk": {
        "common": {
            "fullscreen": "Повноекранний режим",
            "from": "з",
            "videos": "Відео"
        }
    },
    "zh": {
        "common": {
            "fullscreen": "全屏幕",
            "from": "来自",
            "videos": "影片"
        },
        "forum": {
            "close": "关闭"
        }
    },
    "zt": {
        "common": {
            "fullscreen": "全屏幕",
            "from": "來自",
            "videos": "影片"
        },
        "forum": {
            "close": "關閉"
        }
    }
}