import { render, staticRenderFns } from "./Chart.vue?vue&type=template&id=f54fba46&scoped=true&"
import script from "./Chart.vue?vue&type=script&lang=js&"
export * from "./Chart.vue?vue&type=script&lang=js&"
import style0 from "./Chart.vue?vue&type=style&index=0&id=f54fba46&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f54fba46",
  null
  
)

export default component.exports