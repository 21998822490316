/**
 * @see https://github.com/silvermine/videojs-quality-selector
 *
 * videojs, quality-selector-icon in controlbar is only visible, if there more than 1 source
 *
 */
class QualitySelector {

    constructor({player, options = {}}) {
        this.player  = player;
        this.options = options;

        this.showInControlbar();
        // this.onEventQualityRequested(); // testing
    }

    showInControlbar(position = 10)
    {
        this.player.controlBar.addChild('QualitySelector',{},position); // dont add at ending, add "before"
    }

    /**
     * @see https://github.com/silvermine/videojs-quality-selector/blob/master/src/js/events.js
     * @see https://github.com/silvermine/videojs-quality-selector/blob/master/src/js/components/QualitySelector.js
     *
     * @param cb
     */
    onEventQualitySelected(cb){

        this.player.on('qualitySelected', function(event, newSource){

            // btw: video ended -> switching video -> playback at beginning without start -> not firing yet

            // prevents firing multiple times
            /** @see https://docs.videojs.com/docs/api/player.html#Methodspaused */
            let bCanFire = !this.player().paused() || this.player().ended();
            if(bCanFire)
            {
                // console.log('qualitySelector -> qualitySelected', {event, newSource});
                cb && cb(event, newSource);
            }
        })
    }

    /**
     * @see https://github.com/silvermine/videojs-quality-selector/blob/master/src/js/events.js
     * @see https://github.com/silvermine/videojs-quality-selector/blob/master/src/js/components/QualitySelector.js
     *
     * @param cb
     */
    onEventPlayerSourcesChanged(cb)
    {
        this.player.on('playerSourcesChanged', function(){
            // console.log('qualitySelector -> playerSourcesChanged');
            cb && cb();
        });
    }

    /**
     * fires, if user switches quality
     *
     * @see https://github.com/silvermine/videojs-quality-selector/blob/master/src/js/events.js
     * @see https://github.com/silvermine/videojs-quality-selector/blob/master/src/js/components/QualitySelector.js
     *
     * @param cb
     */
    onEventQualityRequested(cb)
    {
        this.player.on('qualityRequested', function(event, newSource){
            // console.log('qualitySelector -> qualityRequested', {event, newSource});
            cb && cb(event, newSource);
        });
    }
}

export default QualitySelector;