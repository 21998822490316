import Socialize from './Socialize';

export default {
    startPageNews: function ({
         html     : {
             root,          //
             $count,        //
             count : {
                 $comments, //
                 $views,    //
             }
         },
         socialize: {
             user  : {
                 id: userId
             },
             entity: {
                 id,
             }
         },
     }) {

        // console.log('ViewCount.js @ startPageNews', {
        // html     : {
        //     root,          //
        //         $count,        //
        //         count : {
        //         $comments, //
        //             $views,    //
        //     }
        // },
        // socialize: {
        //     user  : {id: userId},
        //     entity: {id,}
        // }},);

        Socialize.ready((getInstance)=>{ // TODO optimize to less wait ?

            var me = getInstance({id}); // breakingNews_<id>

            me.onGetMessageCount( count => { // get comments counter
                $comments.find('.counter').html(count);
                if(count > 0){
                    $comments.children().removeClass('d-none');
                    $count.removeClass('d-none').addClass('animated fadeIn');  // bootstrap material design
                }
            });

            me.onGetViewCount( (count) => { // get view counter
                $views.find('.counter').html(count);
                if(count > 0){
                    $views.children().removeClass('d-none');
                    $count.removeClass('d-none').addClass('animated fadeIn'); // bootstrap material design
                }
            });

            $('body').one('inview', root, function(){ // increase view counter
                me.addViewCount({
                    userId,
                    id, // socket -> injects newsId, amqp -> needs newsId
                    timestamp: parseInt((new Date().getTime()).toFixed()), // timestamp with 13 positions
                }, (data) => {
                    // console.log('addViewCount, response', data);
                    if(data['success']){
                        var val = parseInt($views.find('.counter').text());
                        if(!isNaN(val)){ // is number
                            $views.find('.counter').html(val);
                        }else{
                            // $elem.html(0); // disabled, keep old
                        }
                    }
                });
            });
        });
    }
};