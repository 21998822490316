<template>

    <div class="socialize-modal-image-chat">
        <template v-if="itemSelectedNormalized >= 0">

            <transition name="fade" appear>
                <div
                    @click="onClickCloseModal"
                    @touchstart="onMouseDown"
                    @touchmove="onMouseMove"
                    @touchend="onMouseUp"
                    class="modal fade show modal-image-chat overflow-auto"
                    :style="{ display : 'block' }"
                    tabindex="-1" role="dialog" aria-labelledby="gridModalLabel"
                >

                    <!-- left-top : close -->
                    <div class="d-flex flex-nowrap left-top text-wd-gray-dark _text-wd-white--hover corner-top-right pt-25px">

                        <!-- compare video -->
                        <a v-if="items[itemSelectedNormalized]['xhr'] && items[itemSelectedNormalized]['xhr']['sUrlVideoCompare']"
                           target="_blank"
                           style="min-width:40px"
                           class="d-none d-lg-flex ml-2 ml-lg-4 align-items-center line-height-26px bg-black rounded-10px h-40px"
                           :href="items[itemSelectedNormalized]['xhr']['sUrlVideoCompare'] || '#'"
                        >
                            <span class="d-inline-block mdi mdi-swap-horizontal mdi-middle font-size-18px d-flex align-items-center pl-sm-14px px-12px"></span>
                            <span class="d-none d-xl-inline-block font-size-15px pr-14px">{{ $t('movieplayer.compare') }}</span>
                        </a>

                        <!-- download -->
                        <a v-if="items[itemSelectedNormalized]['xhr'] && items[itemSelectedNormalized]['xhr']['sUrlDownload']"
                           target="_blank"
                           class="d-none d-lg-flex ml-2 ml-lg-4 align-items-center line-height-26px bg-black rounded-10px h-40px"
                           style="min-width: 40px"
                           :href="items[itemSelectedNormalized]['xhr']['sUrlDownload'] || '#'"
                        >
                            <span class="d-inline-block mdi mdi-download mdi-middle font-size-18px d-flex align-items-center pl-sm-14px px-12px"></span>
                            <span class="d-none d-xl-inline-block font-size-15px pr-14px">{{ $t('movieplayer.download_2') }}</span>
                        </a>

                        <!-- dropDown -->
                        <div class="ml-2 ml-lg-4 dropdown dropdown__container rounded-10px h-40px p-0 " style="min-width:40px" v-if="itemSelectedDropDownShare.length">

                            <!-- menu button -->
                            <a href="#"
                               class="d-flex align-items-center text line-height-26px bg-black rounded-10px h-40px"
                               id="dropdown-video-share"
                               data-toggle="dropdown"
                               aria-haspopup="true"
                               aria-expanded="false"
                            >
                                <span class="wd-icon wd-icon-share1 mdi-middle font-size-18px d-inline-block pl-md-14px px-12px"></span>
                                <span class="pr-14px font-size-16px d-none d-xl-inline-block">
                                    {{ $t('movieplayer.share') }}
                                </span>
                            </a>

                            <!-- menu items -->
                            <div class="mt-2 dropdown-menu dropdown-menu-right overflow-auto dropdown__list"
                                 aria-labelledby="dropdown-video-share"
                            >
                                <!-- dropdown-v-for -->
                                <template v-for="({bShow = false, url = '#', icon = null, trans = '', clipboard = null}, index) in itemSelectedDropDownShare"

                                >
                                    <a v-if="bShow"
                                       class="dropdown-item px-2 d-flex align-items-center font-size-16px"
                                       target="_blank"
                                       :href="url"
                                       @click="copyToClipboard(clipboard)"
                                    >
                                        <span v-if="icon" :class="['d-inline-block px-2 mdi mdi-middle', icon]"></span>
                                        <span class="d-inline-block text-truncate">{{ $t(trans) }}</span>
                                    </a>
                                </template>
                            </div>

                        </div>

<!--                        dropdown small-->
                        <div class="d-lg-none ml-2 ml-lg-4 dropdown dropdown__container rounded-10px h-40px p-0" v-if="itemSelectedDropDownSmall.length" style="min-width:40px; max-width:200px !important">
                            <a href="#"
                               class="d-flex align-items-center text line-height-26px bg-black rounded-10px h-40px"
                               id="dropdown-video-small"
                               data-toggle="dropdown"
                               aria-haspopup="true"
                               aria-expanded="false"
                            >
                                <span class="wd-icon wd-icon-dots-menu mdi-middle font-size-18px d-inline-block pl-md-14px px-12px"></span>
                            </a>
                            <!-- menu items -->
                            <div class="mt-2 dropdown-menu dropdown-menu-right overflow-auto dropdown__list"
                                 aria-labelledby="dropdown-video-small"
                            >
                                <!-- dropdown-v-for -->
                                <template v-for="({bShow = false, url = '#', icon = null, trans = '', clipboard = null}, index) in itemSelectedDropDownSmall" >
                                    <a v-if="bShow"
                                       class="dropdown-item px-2 d-flex align-items-center font-size-16px"
                                       target="_blank"
                                       :href="url"
                                       @click="copyToClipboard(clipboard)"
                                    >
                                        <span v-if="icon" :class="['d-inline-block px-2 mdi-middle', icon]"></span>
                                        <span class="d-inline-block text-truncate">{{ $t(trans) }}</span>
                                    </a>
                                </template>
                            </div>
                        </div>


                        <!-- video problems -->
                        <a target="_blank"
                           style="min-width:40px"
                           v-if="items[itemSelectedNormalized]['xhr'] && items[itemSelectedNormalized]['xhr']['sUrlVideoProblems']"
                           class="d-none d-lg-flex ml-2 ml-lg-4 align-items-center line-height-26px rounded-10px h-40px bgcolor-premium-orange white text-white"
                           :href="items[itemSelectedNormalized]['xhr']['sUrlVideoProblems']"
                        >
                            <span class="d-inline-block wd-icon wd-icon-video mdi-middle font-size-18px d-flex align-items-center pl-sm-14px px-12px"></span>
                            <span class="d-inline-block font-size-15px pr-14px">{{ $t('movieplayer.video_problems') }}</span>
                        </a>

                    </div>
                    <!-- center-top : several buttons -->
                    <div class="w-100 text-center position-relative" style="z-index: 1;">
                        <div class="d-inline-block middle-top text-wd-gray-dark text-wd-white--hover pt-25px">
                            <div class="mr-2 mr-lg-4 d-flex align-items-center close-modal text line-height-26px bg-black rounded-10px h-40px">
                                <span class="font-size-16px d-inline-block pl-14px pr-14px pr-sm-0">{{itemSelectedNormalized+1}} {{$t('common.from') }} {{items.length}}</span>
                                <span class="font-size-16px d-none d-sm-inline-block pl-3px pr-14px"> {{$t('common.videos')}}</span>
                            </div>
                        </div>
                    </div>

                    <!-- right-top : several buttons -->
                    <div class="d-inline-block right-top text-wd-gray-dark text-wd-white--hover pt-25px">
                        <a class="mr-2 mr-lg-4 d-flex align-items-center close-modal text line-height-26px bg-black rounded-10px h-40px"
                           href="#"
                           style="min-width:40px"
                           @click.prevent.stop="onClickCloseModal('close')"
                        >
                            <span class="mdi mdi-close mdi-middle font-size-18px d-inline-block d-flex align-items-center pl-md-14px px-12px"></span>
                            <span class="font-size-16px d-none d-md-inline-block pr-14px">{{ $t('forum.close') }}</span>
                        </a>
                    </div>

                    <div role="document"
                        :class="[
                            'modal-dialog modal-dialog-centered justify-content-center position-relative '
                            + (true ? 'm-0' : '')
                        ]"
                        :style="{ 'max-width':'unset', 'height' : '100vh' }"
                    >

                        <!-- slider left -->
                        <div v-if="items.length > 1" class="slider left d-sm-inline-flex" :class="{'d-none': hasTouch}">
                            <a href="#" @click.prevent.stop="onClickSlideLeft">
                                <span class="wd-icon wd-icon-arrow24 rounded-50 border-5px font-size-22px ml-25px p-10px rounded-50p" style=" border: solid 2px;"></span>
                            </a>
                        </div>

                        <!-- content -->
                        <div :class="[
                                'overflow-hidden '
                                +(true
                                    ? 'container-xl body-spacing' // fill page (w-100 works too, but left & right side of page needs to be clickable for modal)
                                    : ''      // size by content (video-js needs min-values)
                                )
                            ]">
                            <div class="modal-content position-relative">

                                <div class="modal-body p-0">

                                    <!-- loading animation -->
                                    <div v-show="waiting" ref="loading" class="spinner-rect-5 loading">
                                        <div class="rect1"></div>
                                        <div class="rect2"></div>
                                        <div class="rect3"></div>
                                        <div class="rect4"></div>
                                        <div class="rect5"></div>
                                    </div>

                                    <template v-if="!waiting">

                                        <!-- above video -->
                                        <div v-if="false" :class="['container-fluid']">
                                            <div class="row">
                                                <div class="col-12 bg-white">
                                                    above video
                                                </div>
                                            </div>
                                        </div>

                                        <!-- video -->
                                        <div v-if="!bHtmlError && !bHtmlForce && itemPlaylist.length" :class="['']">
                                            <vue-videojs-wrapper
                                                @play="onVideoPlay"
                                                @pause="onVideoPause"
                                                @ended="onVideoEnded"
                                                @endedLast="onVideoEndedLast"
                                                @playlistitem="onVideoPlaylistitem"
                                                @error="onVideoError"
                                                :playlist="itemPlaylist"
                                                :playlist-show="itemPLaylistShow"
                                            >
                                            </vue-videojs-wrapper>
                                        </div>

                                        <!-- html -->
                                        <div v-else-if="itemHtml" v-html="itemHtml" @click.stop="onClickHtmlRaw"></div>

                                        <!-- below video -->
                                        <div v-if="false" :class="['container-fluid']">
                                            <div class="row">
                                                <div class="col-12 bg-white">
                                                    below video
                                                </div>
                                            </div>
                                        </div>

                                    </template>

                                </div>
                            </div>
                        </div>

                        <!-- slider right -->
                        <div v-if="items.length > 1" class="slider right d-sm-inline-flex" :class="{'d-none': hasTouch}"
                             @click.prevent.stop="onClickSlideRight">
                            <a href="#">
                                <span class="wd-icon wd-icon-arrow3 rounded-50 border-5px font-size-22px mr-25px p-10px rounded-50p" style=" border: solid 2px;"></span>
                            </a>
                        </div>

                    </div>

                    <!-- advertising: video banner -->
                    <div style="margin: -150px auto">
                        <div v-html="banners"></div>
                    </div>
                </div>

            </transition>

            <transition name="fade" appear>
                <div class="modal-backdrop fade show background"></div>
            </transition>

        </template>
    </div>

</template>

<script>
import _throttle      from 'lodash/throttle';
import _get           from 'lodash/get';
import _isPlainObject from 'lodash/isPlainObject';
import clipboard      from './../../js/layout/wd18774/helper/clipboard';
import playlistTest   from './../../js/videojs/testPlaylist';
import videoJsWrapper from './ModalVideo/VideoJsWrapper.vue';

const defaultLocale = window['APP'] && window['APP']['lang'] ? window.APP.lang : 'en';
let defaults = {
    locale              : defaultLocale,
    xhr : {
        sUrlVideoByHash : location.origin+'/movie/fetch-player-json',
    },
    becomePremium: {
        bShowAfterVideo: true,
        bIsIFrame      : false,
        sUrlImg        : '/static/images/movieplayer/become_pro_' + (defaultLocale === 'de' ? 'de' : 'en') + '.png',
        sUrlPage       : '/benefits',
    },
    video : {
        become_premium : 'https://storage01.working-dog.net/h3OrckOxmPmNnKzV5TWNl6O6bEEpj4oM3r2pKamrT94,.mp4',
    }
};

export default {
    components : {
        'vue-videojs-wrapper' : videoJsWrapper
    },
    props: {
        modalOpenSelector : {
            type    : String,
            required: false,
            default : '', // .images-<hash> .image-tile-layout > a > div
            validator( value ) {
                return typeof value === 'string';
            }
        },
        itemInstantPlayHash : {
            type    : String,
            required: false,
            default : null, // .images-<hash> .image-tile-layout > a > div
            validator( value ) {
                return value === null
                    || typeof value === 'string'
                ;
            }
        },
        itemsProp: {
            type    : Array,
            required: false,
            default(){
                return []; // { href, title, uri }
            },
            validator( value ) {
                return Array.isArray(value); // && value.length > 0;
            }
        },
        xhr: {
            type    : Object,
            required: false,
            default(){
                return {
                    sUrlVideoByHash : defaults.xhr.sUrlVideoByHash,
                };
            },
            validator(value) {
                return true;
            }
        },
        becomePremium : {
            type     : Object,
            required : false,
            default(){
                return {
                    bIsIFrame      : defaults.becomePremium.bIsIFrame, // TODO move this to ajax ???
                    bShowAfterVideo: defaults.becomePremium.bShowAfterVideo, // TODO move this to ajax
                    sUrlImg        : defaults.becomePremium.sUrlImg,
                    sUrlPage       : defaults.becomePremium.sUrlPage,
                };
            }
        },
        banners : {
            type     : String,
            required : false,
            default  : ''
        }
        // lazyLoadAmount: {
        //     type    : Number,
        //     required: false,
        //     default : 3,
        //     validator( value ) {
        //         return true; // TODO
        //     }
        // },
    },
    data() {
        return {
            items           : this.itemsProp,
            itemSelected    : -1,
            waiting         : false,
            animate         : true,
            animation       : 'animation fadeInRight',
            xhrPlayerData   : [],
            bHtmlForce      : false,
            bHtmlError      : false,
            mouseDownPositionX : null,
            mouseDownPositionY : null,
            isSwiping: false,
            swipeDistanceX: null,
            hasTouch: ('ontouchstart' in window),
        }
    },
    computed: {
        itemHtml: function(){
            // console.log('ModalVideo -> computed.itemHtml', _get(this.items[this.itemSelectedNormalized], 'xhr.sHtml', ''));
            return this.bHtmlError
                ? _get(this.items[this.itemSelectedNormalized], 'xhr.sHtmlError', '')
                : _get(this.items[this.itemSelectedNormalized], 'xhr.sHtml', '')
                ;
        },
        itemPlaylist: function(){
            // console.log('ModalVideo -> computed.itemPlaylist', _get(this.items[this.itemSelectedNormalized], 'xhr.aPlaylist', []));
            return _get(this.items[this.itemSelectedNormalized], 'xhr.aPlaylist', []);
        },
        itemPLaylistShow: function(){
            // hide playlist (in video-js-wrapper), if playlist includes "become premium" video
            return !this.isPlaylistTeaser(this.itemPlaylist || []);
        },
        itemSelectedDropDownShare: function(){
            let {
                sShareFacebook = null,
                sShareWhatsapp = null,
                sShareEmail    = null,
                sShareEmbed    = null,
                sShareUrl      = null,
            } = this.items[this.itemSelectedNormalized]['xhr'] || {};

            let shareButtons = [];
            shareButtons.push({
                bShow : !!sShareFacebook, url : sShareFacebook || '#',
                icon  : 'mdi-facebook', trans : 'movieplayer.facebook'
            });
            shareButtons.push({
                bShow : !!sShareWhatsapp, url : sShareWhatsapp || '#',
                icon  : 'mdi-whatsapp', trans : 'movieplayer.whatsapp'
            });
            shareButtons.push({
                bShow : !!sShareEmail, url : sShareEmail || '#',
                icon  : 'mdi-email-outline', trans : 'movieplayer.email'
            });
            shareButtons.push({
                bShow : !!sShareEmbed, url : null,
                icon  : 'mdi-xml', trans : 'movieplayer.embed',
                clipboard : sShareEmbed
            });
            shareButtons.push({
              bShow : !!sShareUrl, url : null,
              icon  : 'mdi-clipboard-check-outline', trans : 'movieplayer.clipboard',
              clipboard : sShareUrl
            });

            console.log('itemSelectedDropDownShare', shareButtons);

            return shareButtons.filter(v => v['bShow']);
        },
        itemSelectedDropDownSmall: function(){
            // let {
            //     sUrlVideoCompare = null,
            //     sUrlDownload    = null,
            //     sUrlVideoProblems = null,
            // } = this.items[this.itemSelectedNormalized]['xhr'] || {};
            //
            // let smallButtons = [];
            // smallButtons.push({
            //     bShow : !!sUrlVideoCompare, url : sUrlVideoCompare || '#',
            //     icon  : 'mdi mdi-swap-horizontal', trans : 'movieplayer.compare'
            // });
            // smallButtons.push({
            //     bShow : !!sUrlDownload, url : sUrlDownload || '#',
            //     icon  : 'mdi mdi-download', trans : 'movieplayer.download_2'
            // });
            // smallButtons.push({
            //     bShow : !!sUrlVideoProblems, url : sUrlVideoProblems || '#',
            //     icon  : 'wd-icon wd-icon-video', trans : 'movieplayer.video_problems'
            // });
            //
            // console.log('itemSelectedDropDownSmall', smallButtons);
            //
            // return smallButtons.filter(v => v['bShow']);
            let {
                sUrlVideoCompare = null,
                sUrlDownload    = null,
                sUrlVideoProblems = null,
            } = this.items[this.itemSelectedNormalized]['xhr'] || {};

            let shareButtons = [];
            shareButtons.push({
                bShow : !!sUrlVideoCompare, url : sUrlVideoCompare || '#',
                icon  : 'mdi mdi-swap-horizontal', trans : 'movieplayer.compare'
            });
            shareButtons.push({
                bShow : !!sUrlDownload, url : sUrlDownload || '#',
                icon  : 'mdi mdi-download', trans : 'movieplayer.download_2'
            });
            shareButtons.push({
                bShow : !!sUrlVideoProblems, url : sUrlVideoProblems || '#',
                icon  : 'wd-icon wd-icon-video', trans : 'movieplayer.video_problems'
            });

            console.log('itemSelectedDropDownShare', shareButtons);

            return shareButtons.filter(v => v['bShow']);
        },
        itemSelectedNormalized: function(){
            if(this.itemSelected >= 0) // not -1
            {
                this.waiting = true;
                // fetch via url
                if(!this.items[this.itemSelected])
                {
                    console.log('ModalVideo.vue -> open itemSelected -> selected item does not exist', {
                        items       : this.items,
                        itemSelected: this.itemSelected
                    });
                }
                let hash = this.items[this.itemSelected]
                    ? this.items[this.itemSelected]['hash']
                    : null
                ;
                if( hash && !this.items[this.itemSelected]['xhr'])
                {
                    this.queryXhr(hash).then((r) => {
                        this.xhrPlayerData[this.itemSelected] = r;
                        this.$set(this.items, this.itemSelected, Object.assign(
                            {},
                            this.items[this.itemSelected], {
                                xhr : {
                                    iId             : _get(r, 'aData.iId', null),
                                    iCounterType    : _get(r, 'aData.iCounterType', null),
                                    sHtml           : _get(r, 'aData.sHtml', null),
                                    sHtmlError      : _get(r, 'aData.sHtmlError', null),
                                    aPlaylist       : _get(r, 'aData.aPlaylist', null),

                                    sUrlVideoCompare: _get(r, 'aData.sUrlVideoCompare', null),
                                    sUrlDownload    : _get(r, 'aData.sUrlDownload', null),
                                    sUrlVideoProblems : _get(r, 'aData.sUrlVideoProblems', null),

                                    sShareFacebook  : _get(r, 'aData.sShareFacebook', null),
                                    sShareWhatsapp  : _get(r, 'aData.sShareWhatsapp', null),
                                    sShareEmail     : _get(r, 'aData.sShareEmail', null),
                                    sShareEmbed     : _get(r, 'aData.sShareEmbed', null),
                                    sShareUrl       : _get(r, 'aData.sShareUrl', null),
                                }
                            })
                        );

                        this.$nextTick(()=> {this.waiting = false;}); // this.waiting = false // not working without $nextTick
                    }).catch(error => {
                        console.log('vue-modal-video, fetch, xhr-error', error);
                    });
                }else{
                    this.$nextTick(()=> {this.waiting = false;}); // this.waiting = false // not working without $nextTick
                }
            }

            return this.itemSelected;
        }
    },
    watch: {},
    methods: {
        isPlaylistTeaser(aPlaylist)
        {
            const bPlaylistItems = Array.isArray(aPlaylist) && aPlaylist.length

            if(!bPlaylistItems)
            {
                return false;
            }

            return aPlaylist
                    .map((v,i)=>_get(v,'sources.0.src',''))
                    .includes(defaults.video.become_premium)
                ;
        },
        onVideoPlaylistitem(value){
            // console.log('ModalVideo -> onVideoPlaylistitem', value);
        },
        onVideoPlay(value){
            const selectedItem = this.items[this.itemSelected];
            if (!selectedItem.isViewCounted && selectedItem.xhr.iCounterType && selectedItem.xhr.iId) {
                selectedItem.isViewCounted = true;
                $.post('/movie/countMovieViews', {
                    movieId: selectedItem.xhr.iId,
                    counterType: selectedItem.xhr.iCounterType
                });
            }

            // console.log('ModalVideo -> onVideoPlay', value);
        },
        onVideoPause(value){
            // console.log('ModalVideo -> onVideoPause', value);
        },
        onVideoEnded(value){
            // console.log('ModalVideo -> onVideoEnded', value);
        },
        onVideoEndedLast(value){
            // console.log('ModalVideo -> onVideoEndedLast', value);

            if(this.isPlaylistTeaser(this.itemPlaylist || []))
            {
                if(!this.items[this.itemSelected]['xhr']['sHtml'])
                {
                    console.log('error - video (teaser), but html (become-premium-banner) ?')
                    return;
                }

                this.bHtmlForce = true;
            }
        },
        onVideoError(value){
            // console.log('ModalVideo -> onVideoError', value);
            if(!this.items[this.itemSelected]['xhr']['sHtmlError'])
            {
                console.log('error - no error html to show ?')
                return;
            }
            this.bHtmlError = true;
        },
        onClickHtmlRaw: function(e){
            if(e && e.target && e.target.getAttribute('data-action') === 'close')
            {
                this.close();
            }
        },
        close(){
            this.itemSelected = -1;
        },
        onClickSlideLeft(event)
        {
            let itemBefore = this.itemSelected-1,
                aboveZero  = itemBefore + this.items.length,
                result     = (aboveZero) % (this.items.length);

            this.itemSelected = result;

            // this.onImageLoading(null, 'loading'); // show loading-css-animation earlier
        },
        onClickSlideRight(event)
        {
            let itemAfter  = this.itemSelected+1,
                aboveZero  = itemAfter + this.items.length,
                result     = (aboveZero) % (this.items.length);

            this.itemSelected = result;

            // this.onImageLoading(null, 'loading'); // show loading-css-animation earlier
        },
        onClickCloseModal(event)
        {
            if (this.isSwiping) {
                return false;
            }
            if( event === 'close' ||
                typeof event === 'object' && ( // clicked background
                    event.target.getAttribute('role') === 'dialog' ||
                    event.target.getAttribute('class').includes('close')
                )
            ) {
                console.log('onClickCloseModal');
                // setTimeout(() => document.body.classList.remove('overflow-hidden'), 200); // animation-delay
                this.close();
            }
        },
        normalizeTouch(e) {
            return (e.changedTouches ? e.changedTouches[0] : e);
        },
        onMouseDown(e) {
            if (this.items.length === 0) {
                return;
            }
            if (e.changedTouches && e.changedTouches.length > 1) {
                this.mouseDownPositionX = null;
                this.mouseDownPositionY = null;
                this.isSwiping = false;
                return
            }
            this.mouseDownPositionX = this.normalizeTouch(e).clientX;
            this.mouseDownPositionY = this.normalizeTouch(e).clientY;
        },
        onMouseMove(e) {
            if (this.mouseDownPositionX) {
                this.swipeDistanceX = this.mouseDownPositionX - this.normalizeTouch(e).clientX;
                const swipeDistanceY = this.mouseDownPositionY - this.normalizeTouch(e).clientY;
                this.isSwiping = (Math.abs(swipeDistanceY) < Math.abs(this.swipeDistanceX)) && Math.abs(this.swipeDistanceX) > 10;
            }
        },
        onMouseUp(e) {
            if (this.isSwiping) {
                if (this.swipeDistanceX > 0) {
                    this.onClickSlideRight(e);
                } else {
                    this.onClickSlideLeft(e);
                }
            }
            this.mouseDownPositionX = null;
            this.mouseDownPositionY = null;
            setTimeout(() => {
                this.swipeDistanceX = null;
                this.isSwiping = false;
            }, 400);
        },
        onClickShowModal()
        {
            if(this.modalOpenSelector.length)
            {
                let nodes = document.querySelectorAll(this.modalOpenSelector);

                if(this.items.length > 0 && nodes.length !== this.items.length){
                    console.log('warning - ModalImageChat.vue, onClickShowModal, items !== selector', {
                        items : this.items, selector : this.modalOpenSelector, nodes : nodes
                    })
                }

                nodes.forEach((elem,i) => elem.addEventListener('click', ()=>{

                    // console.log('ModalImageChat', { // debug
                    //     nodes,
                    //     modalOpenSelector : this.modalOpenSelector,
                    //     selection : i,
                    // });
                    this.reset();
                    this.itemSelected = i;
                    // document.body.classList.add('overflow-hidden');
                }, false));
                if(!nodes.length){
                    console.log('warning - ModalImageChat, imageSelector : no dom elements found', {selector : this.modalOpenSelector});
                }
            }else{
                console.log('warning - ModalImageChat, imageSelector : empty')
            }
        },
        setAnimationShadow : _throttle(function(event){
            if (this.$refs.shadow) // fix on closing modal
            {
                this.$refs.shadow.classList.remove('shadow-trigger');
                this.$refs.shadow.offsetWidth; /** @see https://css-tricks.com/restart-css-animation/ */
            this.$refs.shadow.classList.add('shadow-trigger');
            }
        }, 500), // needs to be the same as .shadow-out @ transition-delay
        async queryXhr(hash, cb)
        {
            return await fetch(this.xhr.sUrlVideoByHash+'?movieHash='+hash)
            // .then(response => response.text())
            .then(response => response.json())
            .catch((e) => {
                console.log('error (ModalVideo) - xhr', e);
                return {}
            });
        },
        reset()
        {
            this.bHtmlForce = false;
            this.bHtmlError = false;
        },
        copyToClipboard(value)
        {
            if(!value){
                console.log('copy to clipboard : no data');
                return;
            }

            console.log('copy to clipboard', value)
            clipboard.copyTo(value);

            // TODO success-message
        }
    },
    created(){},
    mounted(){
        this.onClickShowModal(); // register -> click, show video
        if(this.itemInstantPlayHash) // instant playback video by query-string ?play=<hash>
        {
            const aFound = this.items
                .map((v,i,a) => v && v['hash'] === this.itemInstantPlayHash ? i : null)
                .filter((v,i,a)=>v !== null)
            ;
            if(aFound.length)
            {
                this.itemSelected = aFound[0];
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.modal-content {
    background: none !important;
}
.slider {
    color          : #707070;
    font-weight    : bold;
    font-size      : 5em;
    pointer-events : auto; /* to register auto-clicks */

    &.left {
        position : fixed !important; /* image to large, always vertical centered */
        left     : 0;
        top      : 50%;
        transform: translateY(-50%);
        z-index  : 3; /* visible above image */

        border-radius: 50px;
        line-height  : 1em;
        /*background   : rgba(0, 0, 0, 0.05);*/ /* opacity like without "opcatiy :value" on whole content */
    }
    &.right {
        position : fixed !important; /* image to large, always vertical centered */
        right    : 0;
        top      : 50%;
        transform: translateY(-50%);
        z-index  : 3; /* visible above image */

        border-radius: 50px;
        line-height  : 1em;
        /*background   : rgba(0, 0, 0, 0.05);*/ /* opacity like without "opcatiy :value" on whole content */
    }
}
.background {
    background-color : black !important;
    opacity          : 0.8 !important;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.slide-enter-active, .slide-leave-active {
    transition: opacity 0.5s;
}
.slide-enter, .slide-leave-to {
    opacity: 0;
}

img {
    &[lazy=loading] {
        background : none; /* rgba(0, 0, 0, 0.05); */
    }
    &[lazy=loaded], &[lazy=error] {
        background : black;
        transition : background 0.5s;
        width      : 100%; /* ie max-width not working, needs width first */
    }
    &.blur {
        filter         : blur(12px) brightness(200%) opacity(50%);
        -webkit-filter : blur(12px) brightness(200%) opacity(50%);
        /*transform      : scale(1.25);*/ /* deactivated, overlaps with message area */

        width    : 100%;
        height   : 100%;

        &[lazy=loading] {
            filter     : none;
        }
    }
}

.loading {
    position       : fixed;
    z-index        : 2;
    top            : 50%;
    transform      : translate(-50%, -50%);
    left           : 50%;

    background     : black;
    opacity        : 0.5;
    border-radius  : 10px;
    padding-top    : 10px;
    height         : 70px;
    padding-bottom : 10px;

    &-done {
        display : none;
    }
}

div.left-top {
    position    : absolute;
    top         : 0;
    left        : 0;
    z-index     : 3; /* 2 = above image, 1 : above text-block, 3 = above shadow */

    a {
        color: inherit;
    }
}

div.right-top {
    position    : absolute;
    top         : 0;
    right       : 0;
    z-index     : 3; /* 2 = above image, 1 : above text-block, 3 = above shadow */

    a {
        color: inherit;
    }
}

.text-wd-white--hover:hover {
    color : white !important;
}

a.close-modal {
    font-weight : bold;
    font-size   : 2.5rem;
    //color       : #999;
    line-height : 1em;
}

.mdi:hover {
    //color      : white;
    opacity    : 1;
    transition : opacity 250ms, color 250ms;
}

.shadow {
    position   : absolute;
    width      : 100%;
    left       : 0;
    box-shadow : 0 0 50px 50px rgba(56, 56, 56, 0.75) !important;
    z-index    : 2;
    opacity    : 1;

    &-hidden {
        .shadow.top, .shadow.bottom, .title {
            display: none;
        }
    }

    &-trigger {
        .shadow.top, .shadow.bottom, .title {
            opacity          : 0;
            transition       : opacity 1s;
            transition-delay : 500ms;
        }
    }

    &.top {
        top        : 0;
    }
    &.bottom {
        bottom     : 0;
    }

    &.title {
        top           : 0; // cant use %, cause higher than wider -> not same distance
        left          : 0; // cant use %, cause higher than wider -> not same distance
        box-shadow    : none !important;
        color         : white;
        max-width     : calc(100%); // 100% - left - right
        font-size     : 2rem;
        line-height   : 2.5rem; // > font-size, so not cut off
    }

}

.corner-top-right {
    > *:hover {
        color : white;
    }

    .dropdown__container {

        &.show {
            //background : #000000;
            //opacity    : 0.7;
        }

        &:hover, & > .btn:hover {
            color      : white;
            //background : rgba(0,0,0 ,0.3);
        }

        border-radius : 10px;
        padding       : 0 10px;

        .dropdown__list {
            max-width : 100px; /* text-truncate */
        }

    }
}

.become-premium {
    a:hover {
        cursor: pointer !important; // overwrite .movie-section
    }
}

.modal-backdrop {
    //height : 100vh; // default, but somehow doesnt cover whole screen ???
    //width  : 100vw; // default, but somehow doesnt cover whole screen ???
    height : 100%;  // fix, does cover whole screen
    width  : 100%;  // fix, does cover whole screen
}

</style>