<template>
    <div>
        <span v-if="!show" ref="sentence" @click="handleClick"></span>
        <slot v-if="show"></slot>
    </div>
</template>

<script>
    export default { /** @see https://github.com/jypblue/vue-ellipsis */ // original source
        props: {
            data: {
                type: String,
                default: ''
            },
            lineClamp: {
                type: Number,
                default: 1
            },
            lineHeight: {
                type: String,
                default: '22px'
            },
            endChar: {
                type: String,
                default: '...'
            },
            endHtml: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                htmlText: '',
                show    : false,
            }
        },
        methods: {
            handleSubstrSentence: function () {
                var stNode = this.$refs.sentence;
                var html = this.data;
                if (html.length === 0) {
                    console.log('the String is empty');
                    return false;
                }
                if (!stNode) {
                    console.warn('can not get this dom');
                    return false;
                }
                stNode.innerHTML = html;
                var startPos = 0;
                var endPos = html.length;
                var stNodeHeight = stNode.getBoundingClientRect().height || 22;
                // let stNodeLineHeight = stNodeStyles.lineHeight
                // stNodeLineHeight = stNodeLineHeight.slice(0, stNodeLineHeight.length - 2)
                // if (!!this.lineHeight) {
                //   stNodeLineHeight = !!this.lineHeight.indexOf('px') ? this.lineHeight.slice(0, this.lineHeight.length - 2) : this.lineHeight
                // }

                var stNodeLineHeight = this.lineHeight.slice(0, this.lineHeight.length - 2);
                var maxHeight = stNodeLineHeight * this.lineClamp;

                if (stNodeHeight > maxHeight) {
                    while (Math.abs(endPos - startPos) > 1) {
                        var half = Math.ceil((endPos + startPos) / 2);
                        var newhtml = html.substring(0, half);
                        stNode.innerHTML = newhtml;
                        stNodeHeight = stNode.getBoundingClientRect().height || 22;
                        if (stNodeHeight <= maxHeight) {
                            startPos = half;
                        } else {
                            endPos = half;
                        }
                    }

                    while (stNodeHeight > maxHeight) {
                        var _newHtml = stNode.innerHTML.substring(0, stNode.innerHTML.trimRight().length - 1);
                        stNode.innerHTML = _newHtml;
                        stNodeHeight = stNode.getBoundingClientRect().height || 22;
                    }

                    var endStr = !!this.endHtml ? this.endHtml.replace(/<[^>]+>/g, "") : '';
                    var endLen = this.endChar === '...' ? 3 : endStr.length + this.endChar.length;
                    var stNodeLen = stNode.innerHTML.trimRight().length;
                    var stNodeDelStr = stNode.innerHTML.substring(stNodeLen - endLen, stNodeLen);
                    var match = stNodeDelStr.match(/\s+/g);
                    var extraLen = match && match.length ? match.length : 0;
                    var newHtml = stNode.innerHTML.substring(0, stNodeLen - endLen - extraLen) + this.endChar + this.endHtml;
                    stNode.innerHTML = newHtml;
                }else{
                    this.show = true;
                }
            },
            handleClick: function (e) {
                if(e.target.tagName === 'A')
                {
                     this.show = true;
                }
                e.preventDefault();
                e.stopPropagation();
            }
        },
        watch: {
            data: {
                immediate: true,
                deep: true,
                handler: function (value) {
                    var _this = this;

                    this.$nextTick(function () {
                        _this.handleSubstrSentence();
                    });
                }
            }
        },
        mounted: function () {
            this.htmlText = this.data;
            // this.$nextTick(()=>{
            //   this.handleSubstrSentence();
            // })
        },
    }

</script>

<style lang="scss" scoped>
    /* custom css */
</style>