import Vue            from "vue";
import VueTransPlugin from 'vue-i18n';

// fetch translation : btw. cant check for file-existence, cause fs cant be resolved, why ?
import vueTransFile from '../../jscompiled/vue-translation.generated'; // generated translation-file by WD @ scripts/translation/VueTranslation.php

// load translation plugin
Vue.use(VueTransPlugin);

// setup translation plugin
const vueTransPlugin = new VueTransPlugin({
    locale        : window['APP'] && window['APP']['lang'] ? window.APP.lang : 'en', // set locale
    fallbackLocale: 'en',
    messages      : vueTransFile, // set locale messages
    missing       : (locale, keyPath) => { // "stolen" from caniva :D
        let keys = keyPath.trim().split('.');
        return keys[keys.length - 1]
    }
});

export default {
    i18n : vueTransPlugin,
}
