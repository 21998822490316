import _throttle from 'lodash/throttle';

let dataQueue = [];
const _throttleTrackVisit = _throttle(_trackVisit, 2000);

export default {
    trackVisit : function({data : { views }}){
        dataQueue = dataQueue.concat(views);
        _throttleTrackVisit({data : dataQueue});
    }
};

function _trackVisit({data}){
    dataQueue = [];
    $.ajax( {
        url       : '/default/rest/track',
        type      : "POST",
        dataType  : "json",
        async     : true,
        data      : {views : data},
        success   : function (json_response) {}
    } );
}