var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-js-wrapper container-fluid px-0"},[_c('div',{staticClass:"row"}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('video',{ref:"video",class:[
                    'video-js vjs-big-play-centered vjs-fluid w-100 bg-white '+
                    'rounded-8 overflow-hidden '+
                    'video-play-paused-styling '
                ],attrs:{"poster":null,"controls":"","preload":"none"}})]),_vm._v(" "),(_vm.playlistShow && _vm.playlist.length > 1)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row flex-nowrap overflow-auto mx-n1 mt-2"},_vm._l((_vm.playlist),function(ref,i){
                var poster = ref.poster; if ( poster === void 0 ) poster = null;
                var title = ref.title; if ( title === void 0 ) title = null;
return _c('div',{key:i,class:[ 'col-3 col-lg-2 position-relative my-auto px-1'],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.playItem(i)},"mouseover":function($event){return _vm.showTitle(i)},"mouseout":function($event){return _vm.showTitle(-1)}}},[(_vm.playlistItemCurr === i)?_c('div',{staticClass:"playlist-item__shadow-container px-1"},[_vm._m(0,true)]):_vm._e(),_vm._v(" "),_c('img',{staticClass:"img-fluid w-100 rounded",attrs:{"alt":title,"src":poster || _vm.defaults.poster}}),_vm._v(" "),_c('span',{staticClass:"position-absolute absolute-centered font-size-13px line-height-20px text-white text-truncate mw-100"},[(_vm.playlistItemCurr === i)?_c('span',{class:['mdi mdi-middle font-size-20px'
                              +(_vm.playlistItemCurrState === _vm.playlistItemStates.PAUSE ? ' mdi-pause' : '')
                              +(_vm.playlistItemCurrState === _vm.playlistItemStates.PLAY  ? ' mdi-play-circle' : '')
                              ]}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"line-height-20px"},[_vm._v(_vm._s(_vm.$t('movieplayer.part'))+" "+_vm._s(i+1))])])])}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-12 text-white position-relative"},[_c('div',{staticClass:"position-absolute",staticStyle:{"z-index":"1"}},[_vm._v("\n                "+_vm._s(_vm.title)+"\n            ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"playlist-item__shadow-fit"},[_c('div',{staticClass:"playlist-item__shadow-show rounded"})])}]

export { render, staticRenderFns }