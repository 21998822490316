/**
 * copy text to clipboard
 *
 * @see https://stackoverflow.com/a/63035539
 *
 * @param text
 * @returns {boolean}
 */
function copyTo(text) {

    if(!text)
    {
        return false;
    }

    var domTmp = document.createElement("input");
    domTmp.value = text;
    document.body.appendChild(domTmp);
    domTmp.select();
    const bSuccess = document.execCommand("copy");
    document.body.removeChild(domTmp);

    return bSuccess;
}

export default { copyTo }
export {
    copyTo
};