<template>
  <span>
    <span v-for="(run,i) in runs" :key="'run'+i">
      <span  v-if="run.type == 'text'">{{run.text}}</span>
      <emoji v-else
             class="emoji-mart-vue-icon p-0"
             :emoji="run.emoji.id"
             :skin="run.emoji.skin"
             :set="set"
             :size="size"
             :style="{ height : size+'px'}"
      />
    </span>
  </span>
</template>

<script>

    /** @see https://codesandbox.io/s/emoji-mart-test-unicode-test-xi64f */ // source

    import { Emoji } from "emoji-mart-vue-fast";
    import { getTextAndEmojiRuns } from "./../../lib/emojiText/emoji-parse";

    export default {
        name: "emoji-text",
        components: {
            Emoji
        },
        computed: {
            runs() {
                return getTextAndEmojiRuns(this.text);
            }
        },
        props: {
            text: {
                type: String,
                required: false,
                default: ""
            },
            set: {
                type: String,
                required: false,
                default: undefined // "apple", "google", "twitter", "emojione", "messenger", "facebook"
            },
            size: {
                type: [String, Number],
                required: false,
                default: undefined // 16, 20, 32, 64
            }
        }
    };
</script>

<style lang="scss" scoped>
    .emoji-mart-vue-icon {
        vertical-align : middle;
        box-sizing     : content-box;
    }
</style>
