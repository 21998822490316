/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap'); // lodash, axios
require('./vue/vue');

import Socialize     from './js/layout/wd18774/socialize/Socialize';
import viewCount     from './js/layout/wd18774/socialize/viewCount';
import CarouselVideo from './js/layout/wd18774/carousel/Video';
import Video         from './js/layout/wd18774/Video';
import CarouselText  from './js/layout/wd18774/carousel/Text';
import Images        from './js/layout/wd18774/lazyload/Images';
import SlickMogile   from './js/layout/wd18774/lazyload/SlickMogile';
import Mogile        from './js/layout/wd18774/Mogile';
import HelperSticky  from './js/layout/wd18774/helper/Sticky';
import HelperTrackVisit from './js/layout/wd18774/helper/TrackVisit';
import AnimalOverlay from "./js/layout/wd19793/page/dogProfile/animalOverlay";
import MainPictureOverlay from "./js/layout/wd19793/page/dogProfile/mainPicOverlay";

if(window){
    window.wd18774 = _.merge(window.wd18774 || {}, {
        lazyload : {
            Images,
            SlickMogile
        },
        socialize: {
            Socialize,
            ViewCount: viewCount
        },
        Video,
        carousel : {
            Video: CarouselVideo,
            Text : CarouselText
        },
        Mogile,
        helper : {
            sticky : HelperSticky,
            trackVisit : HelperTrackVisit,
        }
    });

    window.wd19793 = _.merge(window.wd19793 || {}, {
        DogProfile : {
            AnimalOverlay,
            MainPictureOverlay,
        },
    });
}

// TODO add polyfills (promise, async, ...)