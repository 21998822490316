<template>
    <div>
        <div v-show="show" class="emoji-chrome" @click="hide"></div>
        <picker
              v-if="!lazyInit"
              v-show="show"
              :show-preview="false"
              :native="false"
              class="emoji"
              color="#2a91e1"
              v-on:blur="focusout"
              @select="select"
              ref="picker"
              :i18n="{
                  search: $t('emoji.search'),
                  notfound: $t('emoji.notfound'),
                  categories: {
                    search: $t('emoji.categories_search'),
                    recent: $t('emoji.categories_recent'),
                    people: $t('emoji.categories_people'),
                    nature: $t('emoji.categories_nature'),
                    foods: $t('emoji.categories_foods'),
                    activity: $t('emoji.categories_activity'),
                    places: $t('emoji.categories_places'),
                    objects: $t('emoji.categories_objects'),
                    symbols: $t('emoji.categories_symbols'),
                    flags: $t('emoji.categories_flags'),
                    custom: $t('emoji.categories_custom'),
                  }
                }"/>
        <button type="button" class="btn border-0 shadow-none d-flex align-items-center justify-content-center p-0"
                :disabled="!this.enabled"
                @click="toggleVisibility">
            <i class="far fa-laugh fa-2x text-wd-gray-dark"></i>
        </button>
    </div>
</template>


<script>
    /** @see https://www.npmjs.com/package/emoji-mart-vue-fast */
    /** @see https://github.com/serebrov/emoji-mart-vue **/
    /** @see https://github.com/serebrov/emoji-mart-vue#convenience-wrappers */ // TODO build wrapper to reduce bundle-size (not using all.json)

    import {Picker} from 'emoji-mart-vue-fast'; // TODO switch emoji-plugin to faster version, way to slow
    import 'emoji-mart-vue-fast/css/emoji-mart.css'

    /** @see https://codesandbox.io/s/mutable-monad-qm4vpoj3j */
    /** @see https://vuejsfeed.com/blog/picking-emoticons-with-the-emoji-mart-vue-component */

    export default {
        components: { Picker },
        props : {
            enabled: {
                type   : Boolean,
                default: true
            }
        },
        data() {
            return {
                show     : false,
                lazyInit : true,
            };
        },

        methods: {
            focusout() {
                console.log('focus-out got called');
            },
            hide() {
                this.show = false;
            },
            toggleVisibility() {
                if(this.lazyInit){
                    this.lazyInit = false;
                }
                this.show = !this.show;
            },
            select(emoji) {
                //let {colons, emoticons, id, name, native, skin, unified } = emoji;
                this.$emit('emojiSelected', emoji);
                //this.show = !this.show;
                this.hide();
            }
        },
    };
</script>

<style lang="scss" scoped>

    .emoji { /** emoji picker positioning */
        position: absolute;
        bottom: 51px;
        right: 0;
        white-Space: normal;
        z-index: 20;
    }
</style>