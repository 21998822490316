import _isPlainObject from 'lodash/isPlainObject';
import Json           from './helper/Json';

class Mogile {

    static async fetchUrls({
       aMogileParameters,
       response: { prop = 'data', propValDefault = []} = {}
    })
    {
        const dfd = $.Deferred();

        if(!aMogileParameters.length){
            dfd.resolve([]);
        }

        // check cache
        const aKeys = { hash:[], invalid : []};
        aMogileParameters.forEach((o, i)=>{
            const {hash = null, mime ='', dim='', entity=''} = o;
            if(entity){
                const key = Mogile._getCacheKey(o),
                      url = Mogile._getCache(key);
                url // btw : each image, even if the same, gets internally a new hash on server side
                    ? aMogileParameters[i] = url // already having url of image hash, so store
                    : aKeys.hash.push(i)         // to query
                    ;
            }else{
                console.log('Mogile, aMogileParameters', {all : o});
                aKeys.invalid.push(i);
            }
        });

        // invalid, default response -> null
        aKeys.invalid.forEach((v,i) => {
            aMogileParameters[i] = null;
        });

        if(aKeys.invalid.length){
            // console.log('Mogile.fetchUrls : invalid '+aKeys.invalid.length+'/'+aMogileParameters.length); // debug
        }

        // get valid hash not being in cache
        const aQuery = aMogileParameters.filter((v,i,org)=> aKeys.hash.includes(i));
        // console.log('Mogile.fetchUrls : querying '+aQuery.length+'/'+aMogileParameters.length); // debug

        if(aQuery.length){
            const sUrl = '/default/picture/fetchurl';

            // TODO ajax-queue, xhr are automatically cancelled, if to many

            $.post(sUrl, {'data': aQuery})
                .done((json) => {
                    const val = Json.decode(json)[prop] || propValDefault;
                    aQuery.forEach((oMogileParams, i)=> {
                        const key = Mogile._getCacheKey(oMogileParams);
                        Mogile._cache.set(key, val[i]);
                    });
                    let iCurr = 0;
                    aKeys.hash.forEach((iHash, i) => {
                        aMogileParameters[iHash] = val[i];
                        iCurr++;
                    });
                    dfd.resolve(aMogileParameters);
                })
                .fail(dfd.fail);
        }else{
            // console.log('Mogile.fetchUrls : all in cache'); // debug
            dfd.resolve(aMogileParameters); // fired before return is ok, will resolve instantly
        }

        return dfd.promise();
    }

    static _getCache(key, valDefault = null)
    {
        return Mogile._cache.has(key)
            ? Mogile._cache.get(key)
            : valDefault;
    }

    static updateCache(aUpdate)
    {
        aUpdate.forEach(({result, params = {}})=>{
            const key = Mogile._getCacheKey(params);
            if(key.length && result){
                // console.log('Mogile.updateCache', {keyParts : params, key, url : result}); // debug
                Mogile._cache.set(key, result);
            }
        })
    }

    static _getCacheKey(o)
    {
        if(!_isPlainObject(o)){
            console.log('error - Mogile -> _getCacheKey -> invalid', o);
            return '';
        }

        const {entity='', hash = o[0] || '', mime='', dim=''} = o;

        return typeof entity === 'string' && entity.length
            ? [entity,hash,mime,dim].join('')
            : '';
    }

    /**
     * window.wd18774.Mogile.echoCache()
     */
    static echoCache()
    {
        Mogile._cache.forEach((v,k,map) => console.log({v,k}));
    }

}

Mogile._cache = new Map();

export default Mogile;