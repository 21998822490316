<template>
    <div class="text-center mr-3">
        <div class="text-center rounded bg-gray px-2 mb-1" width="60px" height="60px">
            <div class="text-wd-green font-weight-bold mb-4" style="font-size: 20px">{{ day }}<br><span class="text-font-black font-weight-bold" style="font-size: 20px">{{ month }}</span></div>
        </div>
            <div class="text-menu-shadow">{{ caption }}</div> 
    </div>
</template>

<script>
// import _throttle      from 'lodash/throttle';
// import _get           from 'lodash/get';
import _isPlainObject from 'lodash/isPlainObject';

export default {
    components : {      
    },
     props: {
        day: {
            type    : String,
            required: true,
        },
        month: {
            type    : String,
            required: true,
        },
        caption: {
            type    : String,
            required: true,
        },
    },
    computed: {},
    watch: {},
    methods: {},
    created(){},
    mounted(){
        // ...init javascript on vue-creation...
    }
}
</script>

<style lang="scss" scoped>
.bg-gray{
    background-color: #f7f7f7;
    // background-color: #f7f7f7;
}
</style>
