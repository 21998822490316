<template>
    <div class="flex justify-content-center rounded mr-2 mt-5 document-box">

        <!-- image / icon -->
        <div class="flex justify-content-center bg-document-box rounded">
            <template v-if="aDocument.urlImage">
                <div class="document-preview-image-container">
                    <img :src="aDocument.urlImage" class="center-block d-block mx-auto img-fluid document-preview-image" @click="onDownloadDocumentClick" />
                </div>
            </template>
            <template v-else>
                <div class="text-center document-preview-image-container">
                    <span :class="[ 'mdi mdi-file-pdf text-wd-green mdi-size cursor-pointer', mdiClassByMimeType]" @click="onDownloadDocumentClick">
                    </span>
                </div>
            </template>
        </div>
        <!-- filename + download -->
        <p class="mt-4 text-center document-name cursor-pointer"
           @click="onDownloadDocumentClick">
            {{ aDocument.filename }}
        </p>
        <!-- filesize -->
        <p class="text-center mt-n2" >
            <small class="text-muted text-center color-grey">
                {{ aDocument.filesize ? aDocument.filesize+' kb' : '323 kb' }}
            </small>
        </p>
    </div>
</template>

<script>
// import _throttle from 'lodash/throttle';
import _get from 'lodash/get';
import _isString from 'lodash/isString';
import axios from 'axios';

export default {
     props: {
         aDocument : {
             type    : Object,
             required: true,
         }
    },
    computed:{
        mdiClassByMimeType : function(){
            const mimeOld = this.aDocument.mime;

            if(!_isString(mimeOld))
            {
                return '';
            }

            let mimeNew = '';
            if(!mimeNew){
                mimeNew = _get(mimeOld, {
                    'application/pdf' : 'mdi-file-pdf'
                });
            }
            if(!mimeNew && mimeOld.includes('pdf'))
            {
                mimeNew = 'mdi-file-pdf';
            }
            if(!mimeNew && mimeOld.includes('word'))
            {
                mimeNew = 'mdi-file-word';
            }
            if(!mimeNew && mimeOld.includes('document'))
            {
                mimeNew = 'mdi-file-document';
            }

            return mimeNew;
         }
    },
    watch: {},
    methods: {
        onDownloadDocumentClick($event) {
            console.log('download', this.aDocument['urlDownload'])
            if(!this.aDocument['urlDownload'])
            {
                return;
            }
            axios({
                url: this.aDocument['urlDownload'], //todo: get real doc url
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', this.aDocument['filename'] || 'unknown');
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        }
    },
    created(){},
    mounted(){
        // ...init javascript on vue-creation...
    }
}
</script>

<style lang="scss" scoped>
    .document-box{
        width: 180px;
        height: 231px;
        padding: 0 21.8px 16px 22.8px;
    }

    .mdi-size {
        font-size: 100px;
    }

    .bg-document-box {
        background-color: #FFFFFF; //#F4F4F4;
    }

    .document-name {
        font-size: 13px;
        line-height: 1.54;
        font-weight: 600;
        color: #474747;
    }

    .document-preview-image-container {
        margin: 0 0 16px;
        padding: 20px 35px;
        border-radius: 10px;
        background-color: #f7f7f7;
    }

    .document-preview-image {
        width: 70px;
        height: 100px;
        cursor: pointer;
        box-shadow: 0 0 30px #EBEBEB;
    }

    .cursor-pointer {
        cursor: pointer;
    }
</style>