import Vue from "vue";

// global
Vue.filter('replace', replaceAll);

// local
export default {
    replaceAll
}

// definitions
function replaceAll(s, replace = {}) {

    Object.keys(replace).forEach(key => {
        s = s.replace(new RegExp(key, 'g'), replace[key]);
    });

    return s;
}