import Vue from 'vue';

// components - fetch
// import Chat          from './components/socialize/Chat.vue'; // TODO re-enable, if socialize is used
import ModalImageChat   from './components/ModalImageChat.vue';
import ModalImageChat2  from './components/ModalImageChat2.vue';
import ModalVideo       from './components/ModalVideo.vue';
import TimeSince        from './components/TimeSince.vue';
import DogProfileHeader from './components/dogProfile/header.vue';
import Breed            from './components/dogProfile/breed/Breed.vue';
import DogDocList       from './components/dogProfile/documents/DogDocList.vue';
import DogVideoList     from './components/dogProfile/videos/DogVideoList.vue';
import PictureBlock     from './components/dogProfile/picture-block/PictureBlock.vue';

// components - config
let vueComponents = { // add new components here, directly used in phtml-files
        // start-page
        'vue-time-since'         : TimeSince,           // time-passed
        'vue-modal-image-chat'   : ModalImageChat,      // picture-modal
        // dog-profile
        'vue-modal-image-chat-2' : ModalImageChat2,     // picture-modal (+facebook-share, slightly different design)
        'vue-modal-video'        : ModalVideo,          // video-modal
        // dog-profile
        'vue-dog-profile-header' : DogProfileHeader,    // header
        'vue-breed'              : Breed,               // breed / breedPlanning
        'vue-dog-doc-list'       : DogDocList,          // document
        'vue-dog-video-list'     : DogVideoList,        // video
        'vue-picture-block'      : PictureBlock,        // picture
    },
    vueComponentKeys = Object.keys(vueComponents);

// components - assign
vueComponentKeys.forEach(sHtmlSelector => Vue.component(sHtmlSelector, vueComponents[sHtmlSelector]));

export default {
    htmlTags: vueComponentKeys
}