class Sticky {

    static setVertical({$elem, initial : {top} = {} })
    {
        setSticky(); // initial
        $(window).resize(setSticky);

        function setSticky() {
            const windowHeight = $(window).height(),
                  elemHeight   = $elem.height();
            if (elemHeight >= windowHeight) { // sticky top
                $elem.css({top: 'calc(100vh - ' + elemHeight + 'px)'});
            } else { // sticky bottom
                $elem.css({top});
            }
        }
    }

}

export default Sticky;